import React, { useState, useEffect, useRef } from 'react'
import { useTheme, useMediaQuery } from '@mui/material'
import { db } from '../../firebase/config' // VERY IMPORTANT: Adjust this path to where your Firebase config is located
import { doc, getDoc } from 'firebase/firestore'

const SoundSlicePlayer = ({ sliceData, transposeOption }) => {
  const [iframeUrl, setIframeUrl] = useState('')
  const iframeRef = useRef(null)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const embedType = isMobile ? 'embed-mini' : 'embed'
  const playerHeight = isMobile ? '150px' : '500px'

  useEffect(() => {
    const fetchSliceVariant = async () => {
      if (sliceData) {
        console.log('SLICE_DATA', sliceData)

        const getSliceId = () => {
          const guidRegex =
            /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i

          if (sliceData.id && guidRegex.test(sliceData.id)) {
            console.log('Using id:', sliceData.id)
            return sliceData.id
          } else if (sliceData._id && guidRegex.test(sliceData._id)) {
            console.log('Using _id:', sliceData._id)
            return sliceData._id
          } else if (sliceData.sliceId && guidRegex.test(sliceData.sliceId)) {
            console.log('Using sliceId:', sliceData.sliceId)
            return sliceData.sliceId
          } else {
            console.log('No valid GUID identifier found. (DB_REFACTOR_NEEDED)')
            return null
          }
        }

        const _sliceID = getSliceId()

        try {
          const sliceRef = doc(db, 'slices', _sliceID)
          const sliceSnap = await getDoc(sliceRef)

          if (sliceSnap.exists()) {
            const slice = sliceSnap.data()

            // If a studentVocalRange is set, the viewer is a coach and should see the student's range.
            let voiceType
            if (localStorage.getItem('studentVocalRange')) {
              voiceType = localStorage.getItem('studentVocalRange')
            } else {
              voiceType = localStorage.getItem('vocalRange') || 'LOW'
            } 

            const selectedVariant = slice.variants.find(
              (v) => v.type.toUpperCase() === voiceType.toUpperCase()
            )

            if (selectedVariant) {
              const soundSliceUrl = `https://www.soundslice.com/slices/${selectedVariant.sliceId}/${embedType}/?show_controls=1&branding=2&api=1`
              console.log('SOUND_SLICE_URL', soundSliceUrl)
              setIframeUrl(soundSliceUrl)

              // Determine the correct transpose value
              const effectiveTranspose =
                transposeOption ??
                (sliceData.startNote ? parseInt(sliceData.startNote, 10) : 0)
              console.log('Effective Transpose:', effectiveTranspose)

              iframeRef.current.onload = () => {
                setTimeout(() => {
                  const message = JSON.stringify({
                    method: 'transpose',
                    arg: effectiveTranspose,
                  })
                  iframeRef.current.contentWindow.postMessage(
                    message,
                    'https://www.soundslice.com'
                  )
                }, 1000) // Delay to ensure iframe is ready
              }
            } else {
              console.error(`No variant found for voice type: ${voiceType}`)
            }
          } else {
            console.error('No slice found with the given sliceId.')
          }
        } catch (error) {
          console.error('Error fetching slice variant:', error)
        }
      }
    }

    fetchSliceVariant()
  }, [sliceData, isMobile, transposeOption])

  return (
    <iframe
      ref={iframeRef}
      title='SoundSlice Player'
      src={iframeUrl}
      width='100%'
      height={playerHeight}
      style={{ border: '1px solid #ccc' }}
      allowFullScreen></iframe>
  )
}

export default SoundSlicePlayer
