import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  updateProfile,
} from 'firebase/auth'
import { doc, setDoc, getDoc, serverTimestamp } from 'firebase/firestore'
import { db } from './firebase/config' // Ensure this path is correct
import { toast } from 'react-toastify'
import googleIcon from '../src/assets/svg/googleIcon.svg' // Ensure this path is correct
import logo from '../src/assets/VocalBrain_Logo.png' // Ensure this path is correct
import './OAuth.css' // Ensure this path is correct

function OAuthButton() {
  const navigate = useNavigate()
  const onGoogleClick = async () => {
    try {
      const auth = getAuth()
      const provider = new GoogleAuthProvider()
      const result = await signInWithPopup(auth, provider)
      const user = result.user

      const docRef = doc(db, 'users', user.uid)
      const docSnap = await getDoc(docRef)

      if (!docSnap.exists()) {
        await setDoc(doc(db, 'users', user.uid), {
          name: user.displayName,
          email: user.email,
          timestamp: serverTimestamp(),
        })
      }
      navigate('/')
    } catch (error) {
      toast.error('Could not authorize with Google')
    }
  }

  return (
    <button className='google-btn' onClick={onGoogleClick}>
      <img src={googleIcon} alt='Sign in with Google' className='google-icon' />
      Sign up with Google
    </button>
  )
}

function SignUp() {
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  })

  const handleChange = (event) => {
    const { name, value } = event.target
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const { email, password, firstName, lastName } = formData

    try {
      const auth = getAuth()
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      )
      const user = userCredential.user

      if (user) {
        await updateProfile(user, {
          displayName: `${firstName} ${lastName}`,
        })

        await setDoc(doc(db, 'users', user.uid), {
          firstName,
          lastName,
          email,
          createdAt: serverTimestamp(),
        })

        toast.success('Registration successful!')
        navigate('/intro/step-1')
      } else {
        throw new Error('User object is null after sign up.')
      }
    } catch (error) {
      console.error(error)
      toast.error(error.message)
    }
  }

  return (
    <div className='signup-container'>
      <div className='brand-logo'>
        <img src={logo} alt='VocalBrain Logo' className='logo' />
      </div>

      <div className='offer-info'>
        <h2>Studio Plan</h2>
        <p className='pricing-amount'>$9.95/month</p>
        <ul className='pricing-features'>
          <li>Efficient Practice Sessions</li>
          <li>Personalized Daily Workouts</li>
          <li>AI-Generated Feedback</li>
        </ul>
        <p className='trial-info'>
          Sign up for a free 30-day trial today — no credit card required!
        </p>
      </div>

      <OAuthButton />

      <div className='or-separator'>OR</div>

      <form className='signup-form' onSubmit={handleSubmit}>
        <input
          type='text'
          className='form-input'
          name='firstName'
          placeholder='First Name'
          required
          value={formData.firstName}
          onChange={handleChange}
        />
        <input
          type='text'
          className='form-input'
          name='lastName'
          placeholder='Last Name'
          required
          value={formData.lastName}
          onChange={handleChange}
        />
        <input
          type='email'
          className='form-input'
          name='email'
          placeholder='Email Address'
          required
          value={formData.email}
          onChange={handleChange}
        />
        <input
          type='password'
          className='form-input'
          name='password'
          placeholder='Password'
          required
          value={formData.password}
          onChange={handleChange}
        />
        <button type='submit' className='submit-btn'>
          Sign Up
        </button>
      </form>

      <div className='link-container'>
        <Link to='/sign-in' className='simple-link'>
          Already have an account? Sign in
        </Link>
        <Link to='#' className='simple-link'>
          Forgot password?
        </Link>
      </div>
    </div>
  )
}

export default SignUp
