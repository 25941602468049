import React, { useState, useEffect } from 'react'
import { Card, CardContent, Typography, Button, Box } from '@mui/material'
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from 'firebase/firestore'
import { format } from 'date-fns'

const ReportsViewer = ({ studentId }) => {
  const [latestReport, setLatestReport] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchLatestReport = async () => {
      const db = getFirestore()
      const q = query(
        collection(db, 'student_reports'),
        where('student_id', '==', studentId)
      )

      try {
        const querySnapshot = await getDocs(q)
        let reports = []
        querySnapshot.forEach((doc) => {
          reports.push({ ...doc.data(), id: doc.id })
        })
        // Sort reports by timestamp to find the latest one
        reports.sort((a, b) => b.timestamp.toDate() - a.timestamp.toDate())
        if (reports.length > 0) {
          setLatestReport(reports[0])
        } else {
          setError('No reports found.')
        }
        setLoading(false)
      } catch (err) {
        console.error('Failed to load reports:', err)
        setError('Failed to load reports. Please check permissions.')
        setLoading(false)
      }
    }

    fetchLatestReport()
  }, [studentId])

  if (loading) return <Typography>Loading...</Typography>
  if (error) return <Typography>{error}</Typography>
  if (!latestReport) return <Typography>No report available.</Typography>

  return (
    <Card
      sx={{
        borderRadius: '20px',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'background.paper',
        color: 'text.primary',
        position: 'relative',
        overflow: 'hidden',
        maxWidth: 345,
        margin: 'auto',
        '&:hover': { boxShadow: '0px 8px 40px rgba(0, 0, 0, 0.2)' },
        '@media (max-width: 600px)': { maxWidth: '100%' },
      }}>
      <CardContent style={{ padding: '16px' }}>
        <Typography
          variant='h5'
          component='div'
          sx={{ fontWeight: 'bold', mb: 1 }}>
          Latest AI Report
        </Typography>
        <Typography variant='subtitle2' component='h3' sx={{ mb: 1 }}>
          Date: {format(latestReport.timestamp.toDate(), 'PPPpp')}
        </Typography>
        <Typography variant='body2' sx={{ mb: 1 }}>
          Your workout has been adjust to focus on breathing exercise to help with long phrases.
        </Typography>
        {/*<Typography variant='body2' sx={{ mb: 1 }}>
          Predicted Label: {latestReport.predicted_label}
        </Typography>
       <Typography variant='body2' sx={{ mb: 1 }}>
          Report ID: {latestReport.id}
    </Typography>*/}
        <Box textAlign='center' sx={{ mt: 2 }}>
          <Button
            variant='contained'
            size='medium'
            href={latestReport.report_url}
            target='_blank'>
            View Full Report
          </Button>
        </Box>
      </CardContent>
    </Card>
  )
}

export default ReportsViewer
