import { Box, Button, Typography } from '@mui/material'
import * as React from 'react'
import { useParams } from 'react-router'
import courseContentMap from './components/util/CourseContent'
import CourseSectionCard from './components/CourseSectionCard'
import Paper from '@mui/material/Paper'
import { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom";

export default function Course() {
  const { id } = useParams()
  // const navigate = useNavigate()
  const [cc, setCc] = useState()
  // const { user } = useSession()
  const { pathname } = useLocation()

   useEffect(() => {
     window.scrollTo(0, 0)
   }, [pathname])

  useEffect(() => {
    setCc(courseContentMap.get(id))
  }, [id])

  if (!cc) {
    // return <h1>No content found. [2]</h1>
    return null
  }

  if (!id) {
    // return <h1>No content found. [1]</h1>
    return null
  }
  // function handleNextBtnClick(nextRoute) {
  //   setSectionComplete(user, cc.seriesKey)
  //   window.scrollTo(0, 0)
  //   navigate(`/course/${nextRoute}`)
  // }

  return (
    <>
      <Box paddingLeft={2} paddingRight={2} paddingBottom={1}>
        <Box
          sx={{
            p: 1,
            // bgcolor: 'background.default',
            display: 'grid',
            // gridTemplateColumns: { md: '1fr 1fr' },
            gap: 1,
          }}>
          {/** <Paper elevation={6}>
            <Box padding={1}> // TODO: No longer suppporting multiple cards per page.
              <Typography variant='h4' component='h4' marginLeft={2}>
                {cc.title}
              </Typography>
            </Box> 
            <Box paddingLeft={2} paddingRight={2} paddingBottom={1}>
              <Typography variant='subtitle1' component='h5'>
                {cc.subtitle}
              </Typography>
            </Box>
            <Box paddingLeft={2} paddingRight={2} paddingBottom={1}>
              <Typography variant='body2' component='div'>
                {cc.description}
              </Typography>
            </Box>
            <Box
              paddingX={2}
              paddingBottom={2}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}></Box>
          </Paper>*/}

          {cc.sections.map((section) => (
            <CourseSectionCard key={section.key} section={section} />
          ))}

          <Box>
            {cc.prevRoute ? (
              <Button variant='contained' size='medium' href={cc.prevRoute}>
                Prev: {cc.prevLabel}
              </Button>
            ) : null}
            &nbsp;&nbsp;
            {cc.nextRoute ? (
              <Button variant='contained' size='medium' href={cc.nextRoute}>
                Next: {cc.nextLabel}
              </Button>
            ) : null}
          </Box>
        </Box>
      </Box>
    </>
  )
}


