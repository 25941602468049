// VideoInfo.js
import React from 'react';
import { Typography } from '@mui/material';

const VideoInfo = ({ date, uploader, videoTitle, isActive }) => {
  const formattedDate = date ? date.toLocaleDateString() : 'Unknown date';

  return (
    <div>
      <Typography variant="subtitle1">Uploaded by: {uploader}</Typography>
      <Typography variant="subtitle1">Date: {formattedDate}</Typography>
      <Typography variant="subtitle1">Status: {isActive ? 'Active' : 'Inactive'}</Typography>
    </div>
  );
};

export default VideoInfo;
