import React, { useState, useEffect } from 'react'
import { getAuth, updateProfile } from 'firebase/auth'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Box,
  Button,
  Container,
  Grid,
  Link,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../firebase/config'

export default function Step8() {
  const auth = getAuth()
  const navigate = useNavigate()
  const [changeDetail, setChangeDetail] = useState(true)
   const [formData, setFormData] = useState({
     firstName: '',
     lastName: '',
     userName: auth.currentUser.displayName,
     email: auth.currentUser.email,
     vocalRange: '',
     learnReason: '',
     genderIdentity: '',
     ageGroup: '',
     singingExperience: '',
     practiceTimeTarget: '',
     weeklyTarget: '',
   })

  useEffect(() => {
    async function fetchUserData() {
      if (!auth.currentUser?.uid) {
        console.log('AUTH_CURRENT_USER_UID_NIL', auth.currentUser)
        return
      }
      const userDoc = doc(db, 'users', auth.currentUser.uid)
      const userSnapshot = await getDoc(userDoc)
      if (userSnapshot.exists()) {
        setFormData((prevState) => ({
          ...prevState,
          firstName: userSnapshot.data().firstName || '',
          lastName: userSnapshot.data().lastName || '',
          vocalRange: userSnapshot.data().vocalRange || '',
          learnReason: userSnapshot.data().learnReason || '',
          genderIdentity: userSnapshot.data().genderIdentity || '',
          ageGroup: userSnapshot.data().ageGroup || '',
          userName: userSnapshot.data().displayName || '',
          singingExperience: userSnapshot.data().singingExperience || '',
          practiceTimeTarget: userSnapshot.data().practiceTimeTarget || '',
          weeklyTarget: userSnapshot.data().weeklyTarget || '',
        }))
      }
    }
    fetchUserData()
  }, [auth.currentUser])


  const onLearnReasonChange = (event) => {
    // NEW HANDLER FOR LEARN REASON
    setFormData((prevState) => ({
      ...prevState,
      learnReason: event.target.value,
    }))
  }

  async function onSubmit() {
    try {
      if (auth.currentUser.displayName !== formData.userName) {
        await updateProfile(auth.currentUser, {
          displayName: formData.userName, // Updated variable reference
        })
      }
      //toast.success('Profile details updated')
    } catch (error) {
      toast.error('Could not update the profile details {updateProfile}.')
    }

    try {
      const ref = doc(db, 'users', auth.currentUser.uid)
      await updateDoc(ref, formData)
      toast.success('User preferences updated')
      navigate('/intro/step-2')
    } catch (error) {
      toast.error('Could not update user preferences {users}.')
    }
  }

  return (
    <React.Fragment>
      <Container maxWidth='md'>
        <Box my={4}>
          <Box mt={2}>
            <form>
              {/* NEW SELECT FOR LEARNING REASON */}
              <FormControl fullWidth margin='normal' variant='outlined'>
                <InputLabel id='learnReasonLabel'>
                  What are your vocal training goals?
                </InputLabel>
                <Select
                  labelId='learnReasonLabel'
                  id='learnReason'
                  value={formData.learnReason}
                  onChange={onLearnReasonChange}
                  label='What are your vocal training goals?'
                  disabled={!changeDetail}>
                  <MenuItem value='PROFESSIONAL'>
                    I am a professional singer.
                  </MenuItem>
                  <MenuItem value='HOBBY'>I sing as a hobby.</MenuItem>
                  <MenuItem value='IMPROVE'>
                    I want to improve my vocal skills.
                  </MenuItem>
                  <MenuItem value='PERFORMANCE'>I perform at events.</MenuItem>
                  <MenuItem value='COMPETE'>
                    I compete in singing competitions.
                  </MenuItem>
                  <MenuItem value='CAREER'>
                    I am considering a career in music.
                  </MenuItem>
                  <MenuItem value='INSTRUCTION'>I am a music teacher.</MenuItem>
                  <MenuItem value='HEALTH'>
                    I sing for health benefits.
                  </MenuItem>
                  <MenuItem value='SPIRITUAL'>
                    I sing for spiritual or religious reasons.
                  </MenuItem>
                  <MenuItem value='OTHER'>Other reasons.</MenuItem>
                </Select>
              </FormControl>
              <Grid container spacing={2} justifyContent='space-between'>
                <Grid item>
                  <Button
                    color='primary'
                    onClick={() => {
                      changeDetail && onSubmit()
                      setChangeDetail((prevState) => !prevState)
                    }}>
                    {changeDetail ? 'Apply change' : 'Edit'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  )
}
