/**
 * SlicePage.js
 *
 * This file defines the React component for the slice detail page. It utilizes
 * the useSliceData custom hook to fetch and display data for a specific slice
 * from Firebase Firestore. The page includes responsive headings, slice details,
 * and navigation buttons, providing a comprehensive view of the slice information.
 *
 * Author: Michael Rutherford
 * Date: 2024.03.06
 */

import React from 'react'
import { useParams } from 'react-router-dom'
import { Box, Paper } from '@mui/material'
// import MusicNoteIcon from '@mui/icons-material/MusicNote'; // Correct music-related icon
// import ResponsiveHeading from '../../components/util/ResponsiveHeading';
import { useSliceData } from '../../firebase/dataHelper' // Changed hook to useSliceData, ensure this is the correct path
import SoundSlicePlayer from '../../components/soundslice/SoundSlicePlayer'
import AudioRecorder from '../../components/recorders/AudioRecorder'

function SlicePage({ additionalInstructions }) {
  const { id } = useParams() // Extracts 'id' from URL parameters
  const { data, isPending } = useSliceData(id) // Fetches data for the slice

  // Conditional rendering to handle loading and error states
  if (isPending) {
    return <div>Loading...</div>
  }

  if (!data) {
    return <div>No Slice data found.</div>
  }

  // Ensuring 'id' is included in the data object passed to the SoundSlicePlayer
  const completeData = { id, ...data } // Completes the data object with 'id' from URL params
  
  return (
    <Box paddingLeft={2} paddingRight={2} paddingBottom={1}>
      <Paper elevation={6}>
        <Box paddingTop={2} paddingLeft={2} paddingRight={2} paddingBottom={1}>
          <SoundSlicePlayer sliceData={completeData} /> 
        </Box>
        <Box paddingTop={2} paddingLeft={2} paddingRight={2} paddingBottom={1}>
          {additionalInstructions}
        </Box>
        <Box paddingTop={2} paddingLeft={2} paddingRight={2} paddingBottom={1}>
          <AudioRecorder />
        </Box>
      </Paper>
    </Box>
  )
}

export default SlicePage
