/**
 * dataHelper.js
 *
 * This file contains utility functions for interacting with Firebase Firestore.
 * It includes operations like fetching slice data, marking sections complete,
 * adding videos, and retrieving the latest lesson for a user.
 *
 * Author: Michael Rutherford
 * Date: 2024.03.04
 */

import { db } from './config'
import {
  collection,
  doc,
  addDoc,
  getDoc,
  serverTimestamp,
  query,
  where,
  orderBy,
  limit,
  getDocs,
} from 'firebase/firestore'
// import { trackActivity } from '../components/util/Helpers'
import { useState, useEffect } from 'react'

export const fetchUserActivities = async (userId) => {
  const q = query(
    collection(db, 'activities'),
    where('userId', '==', userId),
    orderBy('timestamp', 'desc')
  )
  const querySnapshot = await getDocs(q)
  return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
}

export const fetchLessonData = async (currentLessonId) => {
  let ret = null 
  try {
    const docRef = doc(db, 'lessons', currentLessonId)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      const { title, notes } = docSnap.data()
      ret = { title, notes}
    } else {
      console.log('No such document!')
    }
  } catch (error) {
    console.error('Error fetching lesson:', error)
  }

  return ret
}

export const computeStreaks = (activities) => {
  if (activities.length === 0) return 0 // Return 0 if no activities

  // Filter valid activities and sort them by timestamp from oldest to newest
  const validActivities = activities
    .filter((activity) => activity.timestamp && activity.timestamp.seconds)
    .sort((a, b) => a.timestamp.seconds - b.timestamp.seconds)

  let currentStreak = 1 // Start streak at 1 if there are valid activities
  let lastActivityDate = new Date(validActivities[0].timestamp.seconds * 1000)
  lastActivityDate.setHours(0, 0, 0, 0) // Normalize to start of the day

  for (let i = 1; i < validActivities.length; i++) {
    const currentActivityDate = new Date(
      validActivities[i].timestamp.seconds * 1000
    )
    currentActivityDate.setHours(0, 0, 0, 0) // Normalize to start of the day

    const diffDays =
      (currentActivityDate - lastActivityDate) / (1000 * 3600 * 24)

    if (diffDays === 1) {
      currentStreak++
    } else if (diffDays > 1) {
      break // Break if there is a gap of more than one day
    }

    lastActivityDate = currentActivityDate // Update lastActivityDate to current
  }

  return currentStreak
}


export const getLatestLessonForUser = async (uid) => {
  try {
    const q = query(
      collection(db, 'lessons'),
      where('userId', '==', uid),
      orderBy('createdAt', 'desc'),
      limit(1)
    )
    const querySnapshot = await getDocs(q)
    if (!querySnapshot.empty) {
      const lessonDoc = querySnapshot.docs[0]
      const latestLesson = { id: lessonDoc.id, ...lessonDoc.data() }
      console.log('Latest lesson fetched successfully.', latestLesson)
      return latestLesson
    } else {
      console.error('No lessons found for user:', uid)
      return null
    }
  } catch (error) {
    console.error('Error fetching the latest lesson:', error)
    return null
  }
}

// Function to fetch all slices
export const fetchAllSlices = async () => {
  try {
    const snapshot = await getDocs(collection(db, 'slices'));
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error("Error fetching slices:", error);
    return [];
  }
};


// Custom hook to fetch slice data
export function useSliceData(id) {
  
  const [data, setData] = useState(null)
  const [isPending, setIsPending] = useState(true)

  useEffect(() => {
    if (!id) {
      console.error('Invalid id. [w3456]')
      setIsPending(false)
      return
    } else {
      console.log('id', id)
    }

    const fetchSlice = async () => {
      setIsPending(true)
      try {
        const docRef = doc(db, 'slices', id)
        const docSnap = await getDoc(docRef)

        if (docSnap.exists()) {
          setData(docSnap.data())
        } else {
          console.error('ERR_NO_SLICE_DATA', id)
        }
      } catch (error) {
        console.error('ERR_UNHANDLED_SLICE_DATA', error)
      } finally {
        setIsPending(false)
      }
    }

    fetchSlice()
  }, [id])

  return { data, isPending }
}


export const setSectionComplete = async (user, sectionId) => {
  const userDocRef = doc(db, 'users', user.uid)

  const docRef = await addDoc(collection(userDocRef, 'infos'), {
    sectionId: sectionId,
    time: Date.now().toString(),
  })
  console.log('Document written with ID: ', docRef.id)
}

export const addVideo = async (user, videoTitle, url) => {
  const newVideo = {
    videoTitle: videoTitle,
    url,
    views: 0,
    date: serverTimestamp(),
    uploader: user.displayName,
    userId: user.uid,
  }

  await addDoc(collection(db, 'videos'), newVideo)
}

export default addVideo
