import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Markdown from './Markdown'
import Iframe from 'react-iframe';


function CourseSectionCard(props: { section: any }) {
  const { section } = props
  //// Theme https://youtube.com/clip/UgkxWl7b9P2B693_ORW4roTqhHZ3aIDpRi02
  // https://youtube.com/clip/UgkxYHpwJ7SnDI9AmMy0Pxu9M8YrHYWBkGUy

  return (
    <Paper elevation={6}>
      <Box padding={1}>
        <Typography variant='h4' component='h2' marginLeft={2}>
          {section.icon ? section.icon : null}
          {section.icon ? ' ' : null}
          {section.title ? section.title : null}
        </Typography>
      </Box>
      <Box paddingLeft={2} paddingRight={2} paddingBottom={1}>
        <Typography variant='subtitle1' component='h5'>
          {section.subtitle ? section.subtitle : null}
        </Typography>
      </Box>
      <Box paddingLeft={2} paddingRight={2} paddingBottom={1}>
        <Typography variant='body2' component='div'>
          {section.description ? section.description : null}
        </Typography>
      </Box>
      <Box paddingLeft={2} paddingRight={2} paddingBottom={1}>
        {section.youTubeLink ? (
          <iframe
            width='560'
            height='315'
            src={section.youTubeLink}
            title='YouTube video player'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen></iframe>
        ) : null}
      </Box>
      <Box paddingLeft={4} paddingRight={4} paddingBottom={1}>
        <Typography variant='body1' component='div'>
          {section.markdown ? (
            <Markdown className='markdown' key={section.key}>
              {section.markdown}
            </Markdown>
          ) : null}
        </Typography>
      </Box>
      <Box paddingLeft={2} paddingRight={2} paddingBottom={1}>
        {section.appLink ? (
          <Iframe
            url={'https://vbp.vocalbrain.com'}
            width='100%'
            height='700px'
            id='#VBP_IFRAME'
            className='myClassname'
            display='block'
            frameBorder={0}
            allow='microphone https://vbp.vocalbrain.com'
            position='relative'
          />
        ) : null}
      </Box>

      <Box paddingLeft={2} paddingRight={2} paddingBottom={1}>
       SLICE ME
      </Box>
      <Box
        paddingX={2}
        paddingBottom={2}
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}></Box>
    </Paper>
  )
}

export default CourseSectionCard

// <>
