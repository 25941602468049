// CommentForm.js
import React, { useState } from 'react';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { Button, TextField, Box } from '@mui/material';

const CommentForm = ({ videoId }) => {
  const [commentText, setCommentText] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!commentText.trim()) return;

    const newComment = {
      commentText,
      commenterName: 'User', // Replace this with the actual user's name
      commenterId: '123', // Replace this with the actual user's ID
      date: new Date(),
    };

    await addDoc(collection(db, 'videos', videoId, 'comments'), newComment);
    setCommentText('');
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" alignItems="center" mt={3}>
        <TextField
          value={commentText}
          onChange={(e) => setCommentText(e.target.value)}
          label="Add a comment"
          variant="outlined"
          fullWidth
        />
        <Button type="submit" variant="contained" color="primary" sx={{ ml: 2 }}>
          Submit
        </Button>
      </Box>
    </form>
  );
};

export default CommentForm;
