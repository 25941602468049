import React, { useState, useEffect } from 'react'
import { getAuth, updateProfile } from 'firebase/auth'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Button,
  Container,
  Grid,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../firebase/config'

export default function Step2() {
  const auth = getAuth()
  const navigate = useNavigate()
  const [changeDetail, setChangeDetail] = useState(true)
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    userName: auth.currentUser.displayName,
    email: auth.currentUser.email,
    vocalRange: '',
    learnReason: '',
    genderIdentity: '',
    ageGroup: '',
    singingExperience: '',
    practiceTimeTarget: '',
    weeklyTarget: '',
  })

  useEffect(() => {
    async function fetchUserData() {
      if (!auth.currentUser?.uid) {
        console.log('AUTH_CURRENT_USER_UID_NIL', auth.currentUser)
        return
      }
      const userDoc = doc(db, 'users', auth.currentUser.uid)
      const userSnapshot = await getDoc(userDoc)
      if (userSnapshot.exists()) {
        setFormData((prevState) => ({
          ...prevState,
          firstName: userSnapshot.data().firstName || '',
          lastName: userSnapshot.data().lastName || '',
          vocalRange: userSnapshot.data().vocalRange || '',
          learnReason: userSnapshot.data().learnReason || '',
          genderIdentity: userSnapshot.data().genderIdentity || '',
          ageGroup: userSnapshot.data().ageGroup || '',
          userName: userSnapshot.data().displayName || '',
          singingExperience: userSnapshot.data().singingExperience || '',
          practiceTimeTarget: userSnapshot.data().practiceTimeTarget || '',
          weeklyTarget: userSnapshot.data().weeklyTarget || '',
        }))
      }
    }
    fetchUserData()
  }, [auth.currentUser])

  const onGenderIdentityChange = (event) => {
    setFormData((prevState) => ({
      ...prevState,
      genderIdentity: event.target.value,
    }))
  }

  async function onSubmit() {
    try {
      if (auth.currentUser.displayName !== formData.userName) {
        await updateProfile(auth.currentUser, {
          displayName: formData.userName,
        })
      }
      //toast.success('Profile details updated')
    } catch (error) {
      toast.error('Could not update the profile details {updateProfile}.')
    }

    try {
      const ref = doc(db, 'users', auth.currentUser.uid)
      await updateDoc(ref, formData)
      //toast.success('User preferences updated')
      navigate('/intro/step-3')
    } catch (error) {
      toast.error('Could not update user preferences {users}.')
    }
  }

  const handleBack = () => {
    navigate(-1) // Navigate to the previous page
  }

  return (
    <React.Fragment>
      <Container maxWidth='md'>
        <Box my={4}>
          <Box mt={2}>
            <form>
              <FormControl fullWidth margin='normal' variant='outlined'>
                <InputLabel id='genderIdentityLabel'>
                  How do you identify?
                </InputLabel>
                <Select
                  labelId='genderIdentityLabel'
                  id='genderIdentity'
                  value={formData.genderIdentity}
                  onChange={onGenderIdentityChange}
                  label='How do you identify?'
                  disabled={!changeDetail}>
                  <MenuItem value='MALE'>Male</MenuItem>
                  <MenuItem value='FEMALE'>Female</MenuItem>
                  <MenuItem value='NONBINARY'>Non-binary</MenuItem>
                </Select>
              </FormControl>
              <Grid container spacing={2} justifyContent='space-between'>
                <Grid item>
                  <Button
                    color='primary'
                    onClick={handleBack}
                    variant='outlined'>
                    Back
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color='primary'
                    onClick={() => {
                      changeDetail && onSubmit()
                      setChangeDetail((prevState) => !prevState)
                    }}
                    variant='contained'>
                    {changeDetail ? 'Next' : 'Edit'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  )
}
