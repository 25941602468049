// VideoListing.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { DataGrid } from '@mui/x-data-grid';
import { Container, Typography } from '@mui/material';

const VideoListing = () => {
  const [videoList, setVideoList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, 'videos'));
      const videos = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setVideoList(videos);
    };

    fetchData();
  }, []);

  if (videoList.length === 0) {
    return <div>Loading...</div>;
  }

  const columns = [
    {
      field: 'videoTitle',
      headerName: 'Title',
      flex: 1,
      renderCell: (params) => (
        <Link to={`/video/${params.row.id}`}>{params.row.videoTitle}</Link>
      ),
    },
    { field: 'uploader', headerName: 'Uploader', flex: 1 },
    { field: 'date', headerName: 'Date', flex: 1 },
  ];

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Video Listing
      </Typography>
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={videoList}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          pagination
        />
      </div>
    </Container>
  );
};

export default VideoListing;
