// StudentDashboard.jsx
import * as React from 'react'
import Grid from '@mui/material/Grid'
import StudentTable from './StudentTable' 

export default function StudentDashboard() {
  return (
      <Grid spacing={2} container>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <div>
            <StudentTable  />
          </div>
        </Grid>
      </Grid>
  )
}
