import React from 'react'

import MusicVideoIcon from '@mui/icons-material/MusicVideo'
import { MusicNote } from '@mui/icons-material';
// Markdown is now inline. If an external loading is need, see this https://medium.com/@shawnstern/importing-multiple-markdown-files-into-a-react-component-with-webpack-7548559fce6f

const courseContentMap = new Map([
  [
    'home',
    {
      seriesKey: 'home',
      mainImage: '',
      imageLable: '',
      prevLabel: null,
      prevRoute: null,
      nextLabel: 'Overview',
      nextRoute: 'overview',
      sections: [
        {
          key: 'home-01-ltzn',
          title: 'Getting Started',
          icon: <MusicVideoIcon />,
          subtitle: null,
          markdown: `
## How to Use VocalBrain

Welcome to VocalBrain, your comprehensive platform for vocal training and musical growth. Our system combines innovative technology tools, a holistic methodology, and a user-friendly dashboard to ensure you get the absolute best from your work with a vocal coach.

### Our Goals:
- **Strengthen your voice.**
- **Build solid vocal techniques.**
- **Enhance your musical knowledge.**
- **Boost your performance confidence.**

By diligently following our methods, you'll maximize the benefits of your vocal training. Remember, it's essential to never strain your voice. Especially for beginners, it's crucial to understand the importance of rest. If you're new to singing, start with workouts no longer than 20 minutes and gradually increase as you become more comfortable. Overtraining can be counterproductive and even lead to setbacks. Most importantly, enjoy the process, have fun, and nurture your love for music!

## Navigating VocalBrain:
- **Dashboard:** Your central hub for tracking progress, accessing resources, and managing your training.
- **Workouts:** Dive into curated collections of exercises, training apps, and vocal exercises tailored to your needs.
- **Plan Page:** Manage your subscriptions and explore premium features to enhance your training experience.
- **Help Section:** Got questions? Find answers, tips, and guidance here.

## How This System Is Designed:
Our course is structured in sections, allowing you to progress at your own pace. Each section offers a blend of reading materials, exercises, apps, videos, and other valuable resources. 

Our interactive **Exercises** and **Apps** are integral to the VocalBrain experience. They're designed to adapt to your vocal range and goals, offering a personalized training journey. Specific apps focus on honing skills like pitch accuracy and breath control, ensuring a well-rounded development.
`, // GRAMMARLY CHECKED
          exerciseId: null,
        },
      ],
    },
  ], // end home

  [
    'overview',
    {
      seriesKey: 'overview',
      mainImage: '',
      imageLable: '',
      prevLabel: 'Welcome',
      prevRoute: 'home',
      nextLabel: 'Workouts',
      nextRoute: 'workouts',
      sections: [
        {
          youTubeLink:
            'https://www.loom.com/embed/51a5b5be942545298aedf7c500930456?sid=1f45a57d-6378-43fa-ac5d-d955245b66cb',
        },
        ,
      ],
    },
  ], // end youtube video

  [
    'workouts',
    {
      seriesKey: 'workouts',
      title: 'VocalBrain Workout Collections',
      subtitle: 'Your Path to Vocal Mastery',
      description:
        'A guided journey through foundational to advanced vocal exercises.',
      mainImage: '', // You can provide a link to an image representing the workout collections.
      imageLabel: 'Workout Collections Overview',
      prevLabel: 'Welcome',
      prevRoute: 'home',
      nextLabel: 'Warmup',
      nextRoute: '5-minute-warmup',
      sections: [
        {
          key: 'workout-collections-01-ltzn',
          title: 'Introduction to Workout Collections',
          icon: <MusicNote />, // This can be an icon representing the workout collections.
          subtitle: null,
          youTubeLink:
            'https://www.loom.com/embed/51a5b5be942545298aedf7c500930456?sid=1f45a57d-6378-43fa-ac5d-d955245b66cb',
          markdown: `Habit formation is crucial in vocal development, and VocalBrain's Workout Collections offer a structured path to harnessing this power. By consistently engaging with exercises tailored to various skill levels—from basics to advanced techniques—singers can steadily enhance their vocal abilities. Critical to this journey is the integration of regular workouts, which serve as a foundation for building and refining vocal skills. These sessions, complemented by feedback from AI and expert coaches, ensure progress is both measurable and aligned with individual goals. For a deeper understanding, explore our accompanying video, which underscores the significance of maintaining a disciplined practice regimen to achieve vocal mastery.`,

          exerciseId: null,
        },
      ],
    },
  ],
  [
    '5-minute-warmup',
    {
      guid: '',
      seriesKey: '5-minute-warmup',
      title: 'Vocal Health',
      subtitle: 'The Essentials to Building Good Habits',
      description:
        'The first part of this course will focus on vocal health, starting with warmup exercises.',
      mainImage: '',
      imageLable: '',
      prevLabel: 'Welcome',
      prevRoute: 'home',
      nextLabel: 'Lip Trills',
      nextRoute: 'lip-trills-01',
      sections: [
        {
          key: 'lip-trills-01-sec-01',
          title: '5 Minute Vocal Warmup',
          icon: <MusicVideoIcon />,
          subtitle: 'Sirens are performed by sliding between notes.',
          markdown: `
# The Importance of Vocal Health

Proper warmups will get your vocal cords and muscles in an active yet relaxed state that will help you produce the best sounds.
Like any other physical activity, proper warmups help prevent injury and allow you to get the most benefit from your practice time.
You should treat your warmups as a routine that helps your mind focus, relax, and center.

Don't view warmups as something you must do, but as a key element to a successful training program. You will be amazed at how consistency and routine will improve you performances.

The video below is from Jacob Burton Studios. Jacob is a professional vocal coach in Nashville, Tennessee, who has worked with everyone from beginners to Grammy winners. He's going to introduce you to some effective ways to warm up your voice.

`,
          exerciseId: null,
          youTubeLink: 'https://www.youtube.com/embed/gW_SoUs2twg',
        },
        {
          key: 'lip-trills-01-section-02',
          title: null,
          icon: null,
          subtitle: null,
          markdown: `
Good! Please watch the video as man times as you need to get the hang of it. When you are ready, click the 'Lip Trills' button below.

## Let's learn our first Exercise! 
`,
          exerciseId: null,
        },
      ],
    },
  ], // end 5 minute vocal warmup, approx 11 minutes
  [
    'lip-trills-01',
    {
      seriesKey: 'lip-trills-01',
      title: 'VocalBrain Exercises',
      subtitle: 'How To Use VocalBrain.',
      description: 'How to Get the Most from VocalBrain Exercises',
      mainImage: '',
      imageLable: '',
      prevLabel: 'A 5-minute-warmup',
      prevRoute: '5-minute-warmup',
      nextLabel: 'Warming Up',
      nextRoute: 'warmup-01',
      sections: [
        {
          key: 'home-01-ltzn',
          title: 'Lip Trills',
          icon: <MusicVideoIcon />,
          subtitle: 'VocalBrain Scale Player',
          markdown: `
This section contains your first VocalBrain exercise. Each Exercise includes instructions and basic tips for how to best use it. For example, an Exercise will often have a suggested vowel sound, but please use whatever syllable you feel best. Each exercise can be adjusted by selecting the most comfortable octave for your vocal range (the 8vb swtich), the current key (Transpose), and the number of times the phrase will play (Repetitions). Notice that you can also click on the gear icon and adjust the playback speed of the musical phrase.

`,
          exerciseId: 'lip-trills-01',
        },
        {
          key: 'lt-102',
          title: 'More About Exercises',
          icon: <MusicVideoIcon />,
          subtitle: 'Getting the Most Out of VocalBrain Exercises',
          markdown: `
It's usually a good idea to start out slow and not rush through the Exercises. Concentrated and accurate practice allows you to hear your tone and technique better. Rushing through an Exercise sloppily will not yield the best results. Remember, practice doesn't make perfect, only perfect practice makes perfect.

Practicing slowly also helps to build the stamina needed to sing more extended notes and phrases.

Generally, you should start at the bottom of your range and move up through the keys until you reach the top of your comrfortable range.
Never strain yourself! Injury can lead to major setbacks in your development as a singer. Always make sure you are hydrated.

As you saw in the video, the goal is to make a gentle /brrr/ sound with you lips as you follow along with a scale.

This first Exercise follows-up on the lip trills that were introduced in the video. Here are some tips and reminders:

- If you have having trouble, use your fingers to support the corners of your mouth
- Your lips should remain essentially closed and your cheeks relaxed
- You should not release too much air too quickly, but keep your exhale smooth and even

Tips:

- Make sure your lips are moist
- Relax your jaw and rub the sides of your cheeks to warm up the muscles
- Don't force or strain as a gentle push of air should be all you need
- Use your hands to lift your cheeks up and relax your jaw (slightly as your lips need to remain closed)

On the next page suggestions on body warmups. But you will notice that will cover more about proper warmups and revisit Lip Trills several times in this Course.


## Now lets talk about body warmups!
`,
          exerciseId: null,
        },
      ],
    },
  ], // end lip trills -

  [
    'warmup-01',
    {
      seriesKey: 'warmup-01',
      title: 'Warming Up Your Body',
      subtitle: '',
      description: '',
      mainImage: '',
      imageLable: '',
      prevLabel: 'Lip Trills',
      prevRoute: 'lip-trills-01',
      nextLabel: 'Larynx Control',
      nextRoute: 'larynx-dampening-01',
      sections: [
        {
          key: 'key-sec-01',
          title: 'Basic Warm-up Routine',
          icon: <MusicVideoIcon />,
          subtitle: 'An Essential Warm-up for Daily Practice',
          markdown: `

Just like anything else physical or athletic, you must warm up your voice just like you would your fingers before playing guitar, piano, or a sport. Warmups are essential for maintaining good vocal health and preventing injury. Strain and overuse will cause setbacks in your development as a singer. Always put your vocal health first to achieve your best singing voice. 

When you are warming up, some aspects of singing will come naturally to you like your breathing and mouth positioning. Other important factors like posture and relaxation of your neck and shoulders require focues and attention. So when you are warming up, really think about your posture. You should make a deliberate effort to relax, stretch and loosen up the extrinsic muscles in your neck and shoulders.

A light warmup should take 5-7 minutes.

## Try the following exercises:

- Breath in and out as you lift your arms horizontally and touch your fingers over your head.
- Stretch your neck toward your shoulder.
- You can put your hand on your head to help, but be carefult not to straing.
- Turn you neck slowly from side to side.
- Pull your chin toward your throat and arch your back slightly.
- Lean over and touch your toes.
- Take several large, relaxed breathes while you imagine an invisible cord stretching your neck, shoulders, and back.
- Stand with your legs shoulder width apart and twist gently at your waist.
- Roll you shoulders forward and back.
`,
          exerciseId: null,
          youTubeLink: null,
        },
      ],
    },
  ], // end warmup body

  [
    'pitch-training-01',
    {
      seriesKey: 'pitch-training-01',
      title: 'Pitch Training',
      subtitle: 'How to Use the VocalBrain Apps',
      description: '',
      mainImage: '',
      imageLabel: '',
      prevLabel: 'warmup-01',
      prevRoute: 'Warming Up',
      nextLabel: 'Larynx Control',
      nextRoute: 'larynx-dampening-01',
      sections: [
        {
          key: '',
          title: 'VocalBrain Apps',
          icon: <MusicVideoIcon />,
          subtitle: 'How to Get the Most Out of Our Apps',
          markdown: `
Welcome to the first VocalBrain training App. 

They can also be sped up and slowed down as needed. It's usually a good idea to start out slow. Practicing slowly allows you to hear your tone and technique better. Practicing slowly also helps to build stamina needed to singer longer notes and phrases.

 ## Keep the following in mind for effective training:

- Try not to slide into notes (unless the exercise says to do so)
- Don't tighten your throat muscles
- Don't forget to breath!

Here are the instructions for this App:

1. Choose your vocal range.
2. Choose an exercise.
3. Set the speed and other options.
4. Listen to the piano chords (the yellow notes).
5. Sing along with the synthesizer (the green notes).
6. The app will show how your pitch (the red line).
7. Press the play button (triangle) when you are ready.


`,
          exerciseId: null,
          youTubeLink: null,
          appLink: {
            text: 'Pitch Trainer',
            linkTo: 'https://vb-pitch.web.app',
          },
        },
      ],
    },
  ], // pitch training
  [
    'larynx-dampening-01',
    {
      seriesKey: 'larynx-dampening-01',
      title: 'Lowering Your Larynx',
      subtitle: 'The importance of controlling your larynx for better tone.',
      description: '',
      mainImage: '',
      imageLabel: '',
      prevLabel: 'Warming Up',
      prevRoute: 'warmup-01',
      nextLabel: 'Breath Control',
      nextRoute: 'sss-sss-whoh',
      sections: [
        {
          key: '',
          title: 'Larynx Control',
          icon: <MusicVideoIcon />,
          subtitle: 'The Dopey /GOH/ Exercise',
          markdown: `
The larynx is the physical mechanism that allows you to create sound. It is consists of two primary muscle groups that either stretch or relax your vocal cords that will determine the pitch produced as air passes through them. Stretching the vocal cords makes a higher pitch, and relaxing them produces lower pitches.

However, learning how to sing with a lowered, stabilized larynx will actually help you reach higher notes without strain.

In this exercise you will practice making lower tones for larynx control.

While perfroming this exercise, try changine the shape of your tongue, jaws, soft palate, lips and another parts of your anatomy related to making sound. Remember, the vocal cords amy make the pitch, but it is the vocal cavities that shape the tone of your voice.

Many of the VocalBrain Exercises will vocal on a specific part of the human anatomy related to producing sound. This is to build conscious awareness of how the physical factors influnce your singing voice both isolated and in conjunction with eatch other. By learning to control these building blocks of singing, you will increase not only your ability to sing with confidence but also to learn how to add artistry and effects to your repetoire.

Key points:

- Your lungs push air over your vocal cords
- The vocal folds then vibrate to create sound waves
- The spaces in your chest and head (sometimes caled resonators) add timbre, color and tone

A lot of singers have fun with this exercise!

`,
          exerciseId: 'larynx-01',
          youTubeLink: null,
        },
      ],
    },
  ], // larynx dampening
  [
    'sss-sss-whoh',
    {
      seriesKey: 'sss-sss-whoh',
      title: 'Breath Control',
      subtitle: 'How to Use the VocalBrain Apps',
      description: '',
      mainImage: '',
      imageLable: '',
      prevLabel: 'Larynx Dampening',
      prevRoute: 'larynx-dampening-01',
      nextLabel: 'Tongue Position',
      nextRoute: 'positioning-02',
      sections: [
        {
          key: '',
          title: 'Using Your Abs',
          icon: <MusicVideoIcon />,
          subtitle: 'Intro to Using Your Abs to Support Your Voice',
          markdown: `
Beautiful tone and stable pitch does not require a lot breath pressure or forced muscular power. In fact, you should always be relaxed and not tense, rigid, or anxious. In fact, the instrumentality of your voice will come naturally through what ins in physics is called Bernoulli's principle. (It applies to a number of other areas, but we'll leave it to you to research on your own).

With that in mind, our goal is then to regulate, and not to force, the breath by training how its natural flow develops and resonates. If you practice these exercises correctly and frequently, great tone should develop and flow effortlessly.

The first Exercise will focus on part of learning to control your breath with the help of your abdominal muscles. For this exercise, you may wish to play a hand over your stomach so that you can feel the muscles tense and relax.

For this exercise:

- Take a deep breath
- Make two forceful breaths out making an /SSS/ sound
- Sing a descending scale of "five, four, three, two, one"
- Relax in between

This will strenghten your muscles and help you develop conscious control over a group of muscles you may not normally associate with singing.

`,
          exerciseId: 'sss-sss-whoh',
          youTubeLink: null,
        },
      ],
    },
  ], // breath 1
  [
    'positioning-02',
    {
      seriesKey: 'positioning-02',
      title: 'Tongue Positioning 101',
      subtitle: 'Develop Conscious Awareness of Your Tongue Positioning',
      description: '',
      mainImage: '',
      imageLable: '',
      prevLabel: 'Breath Control',
      prevRoute: 'sss-sss-whoh',
      nextLabel: null,
      nextRoute: null,
      // nextLabel: '"Mum" Exercise',
      // nextRoute: 'mum',
      sections: [
        {
          key: 'insert_guid_here',
          title: 'Tongue Positioning',
          icon: <MusicVideoIcon />,
          subtitle: 'Practicing Moving Your Tongue Out of the Way',
          markdown: `

The tongue is often called the worst enemy of the singer. In this course, we will discuss various ways to positioning your tongue while you are singing. One of those ways is to lightly press the tongue to the bottom of the back teeth. But first, we are going to experiment with what it sounds like to sing with the tongue completely out of the way.

To do this, stick your tongue out of your mouth. If you think it is sticking out far enough, try to go just a little bit further. To help, you can grab the end of your tongue with your fingers (wash your hands first).

Now, practice this exercise in that position. You will be making a /NYUH/ sound. It may seem uncomfortable at first, but try to sing as well as you can. Pay attention to your mouth position, the muscles being used, and the sound qualities your are producing.

- In addition to the /NYAH/, try other sounds also
- Try to keep the jaw relaxed
- You may feel a little silly already, so have fun with it

Practice this exercise often, as it will help set a foundation for the other tongue exercises we will learn.
`,
          exerciseId: 'mum',
          youTubeLink: null,
        },
        {
          key: 'insert_guid_here',
          title: 'More Content Coming Soon!',
          icon: <MusicVideoIcon />,
          subtitle: 'End of Current Beginner Lesson',
          markdown: `
VocalBrain is committed to release new content almost daily. Look for news and updates.
`,
          exerciseId: null,
          youTubeLink: null,
        },
      ],
    },
  ], // positioning 2
  [
    'mum',
    {
      seriesKey: 'mum',
      title: 'Mum',
      subtitle: '',
      description: '',
      mainImage: '',
      imageLable: '',
      prevLabel: 'Tongue Positioning',
      prevRoute: 'positioning-02',
      nextLabel: 'More Larynx Dampening',
      nextRoute: 'positioning-03',
      sections: [
        {
          key: '',
          title: 'TITLE',
          icon: <MusicVideoIcon />,
          subtitle: '',
          markdown: `
Vocal health. Posture. Breathing. Relaxation. Foundations. Healthy singing. 

Mouth & throat. Really open up. Space by lifting soft palate (sp); as always try to lower larynx.
Adducted cords.
Relax.

`,
          exerciseId: 'mum',
          youTubeLink: null,
        },
      ],
    },
  ], // mum exercise
  [
    'positioning-03',
    {
      seriesKey: 'positioning-03',
      title: 'More SSS-SSS-WHOH',
      subtitle: 'Put a subtitle here',
      description: '',
      mainImage: '',
      imageLable: '',
      prevLabel: '"Mum" Exercise',
      prevRoute: 'mum',
      nextLabel: 'Open /AH/ Exercise',
      nextRoute: 'positioning-04',
      sections: [
        {
          key: '',
          title: 'TITLE',
          icon: <MusicVideoIcon />,
          subtitle: 'I am the subtitle',
          markdown: `
      
          //
// In this post, we show you how you can use these diaphragmatic breathing methods to enhance your singing performance and we list out twelve of the best exercises to try.
// When it comes to singing, breathing is really important. If you go to sing something and suddenly start gasping for breath, the performance is often over. It brings the audience back into reality, away from the magic of the song.

// Unfortunately, most singers experience this at some point while they learn. But just as you can improve your ability to reach high notes or stay within your vocal range, you can also learn to manage your breath properly. It’s all about breathing from the diaphragm. The organ plays a central role in singing.

// Learning how to control your breathing directly affects the quality of your voice as well as your volume, pitch, and tone. So to sing better, you must re-train your respiratory system.

// Proper breathing expands the abdomen as you inhale. You may also hear this process called horizontal breathing. Most people instead use vertical breathing, which causes the chest and shoulders to rise. The result is short breaths and an unsupported voice.

`,
          exerciseId: 'sss-sss-whoh-5-1',
          youTubeLink: null,
        },
      ],
    },
  ], // positioning 3
  [
    'positioning-04',
    {
      seriesKey: 'positioning-04',
      title: 'Open /AH/',
      subtitle: 'Put a subtitle here',
      description: '',
      mainImage: '',
      imageLable: '',
      prevLabel: 'More Larynx Dampening',
      prevRoute: 'posititionin-04',
      nextLabel: 'Health Tips',
      nextRoute: 'healthtips-01',
      sections: [
        {
          key: '',
          title: 'TITLE',
          icon: <MusicVideoIcon />,
          subtitle: 'I am the subtitle',
          markdown: `
Open Ah.
- Relax the jaw.
- TODO: anather exercise with YAH
- use a 1 3 5 3 1 that hasn't been used. maybe one without a chord.

ah and yah to relax the areas singers tend to tense
the jaw and the txngue - (sp to remove from search resuults)

`,
          exerciseId: 'open-ah',
          youTubeLink: null,
        },
      ],
    },
  ], // open ah
  [
    'healthtips-01',
    {
      seriesKey: 'healthtips-01',
      title: 'Health Tips',
      subtitle: 'Put a subtitle here',
      description: '',
      mainImage: '',
      imageLable: '',
      prevLabel: 'Open /AH/',
      prevRoute: 'positioning',
      nextLabel: 'Mi Me Ma Mo Mu',
      nextRoute: 'mimemamomu-asc-01',
      sections: [
        {
          key: '',
          title: 'TITLE',
          icon: <MusicVideoIcon />,
          subtitle: 'I am the subtitle',
          markdown: `
          Drink lots of water.
covered already:
- stretched & relaxed
- body warmup
- lip trills; sirens (was enough covered about sirens? or a siren specific exercise?)
- gradual
- start out in comfortable registers and then extend as you warm up

- a3lays listen to your body
f your body is telling you something’s not right, listen to it! 

- work with your anatomy, not agaisnt it
- proper alignment
- breath control

`,
          exerciseId: 'positioning-03',
          youTubeLink: null,
        },
      ],
    },
  ], // vocal health tips
  [
    'mimemamomu-asc-01',
    {
      seriesKey: 'mimemamomu-asc-01',
      title: 'Mi Me Ma Mo mu Ascending',
      subtitle: 'Put a subtitle here',
      description: '',
      mainImage: '',
      imageLable: '',
      prevLabel: 'Health Tips',
      prevRoute: 'healthtips-01',
      nextLabel: 'ooo exercis',
      nextRoute: 'ooh-01',
      sections: [
        {
          key: '',
          title: 'TITLE',
          icon: <MusicVideoIcon />,
          subtitle: 'I am the subtitle',
          markdown: `
          Important info here.
`,
          exerciseId: 'positioning-03',
          youTubeLink: null,
        },
      ],
    },
  ], // mi me ma mo mu asc
  [
    'ooh-01',
    {
      seriesKey: 'ooh-01',
      title: '/OOH/ Exercise',
      subtitle: 'Put a subtitle here',
      description: '',
      mainImage: '',
      imageLable: '',
      prevLabel: 'vocal health',
      prevRoute: 'healthtips-01',
      nextLabel: 'ooh 2',
      nextRoute: 'ooh-02',
      sections: [
        {
          key: '',
          title: 'TITLE',
          icon: <MusicVideoIcon />,
          subtitle: 'I am the subtitle',
          markdown: `
- Lift the soft palate.

- the importance of routine
- structuring and planning a session
- INTRO TO WORKOUTS

`,
          exerciseId: 'ooh-01',
          youTubeLink: null,
        },
      ],
    },
  ], // oooh exercise
  [
    'ooh-02',
    {
      seriesKey: 'ooh-02',
      title: '/OOH/ Exercise #2',
      subtitle: 'Put a subtitle here',
      description: '',
      mainImage: '',
      imageLable: '',
      prevLabel: 'vocal health',
      prevRoute: 'ooh-01',
      nextLabel: 'Breath #2',
      nextRoute: 'sss-sss-whoh-5-1',
      sections: [
        {
          key: '',
          title: 'TITLE',
          icon: <MusicVideoIcon />,
          subtitle: 'I am the subtitle',
          markdown: `
low relaxed breath, ribs, back expand
lift arms up, exhale arms down

relax so abdomen can move freely

oo walkdown for improve resonance

oo is the easiest to sing with a pleasant tone compare to other
good resonance
the mum
creates some space in the mouth and the throat
stabilize the larynx
so it doesn't move excessively
cord compression

mix voice

full chest voice

exercises for agility and runs

posture, breathing, relaxiation
ALWAYS

stand tall, breathe low

each day can look different
shorter practices more frequently

daily practice is ideal
doesn't have to be extremely regimented
don't have to TRAIN every day, but try to sing every day

TOOD: Identify things that should be able to do before an exercise, like a prereqquisite

`,
          exerciseId: 'ooh-02',
          youTubeLink: null,
        },
      ],
    },
  ], // oooh exercise 2 - with 5 4 3 2 1
  [
    'sss-sss-whoh-5-1',
    {
      seriesKey: 'sss-sss-whoh-5-1',
      title: 'Breath Exercise #2',
      subtitle: 'Put a subtitle here',
      description: '',
      mainImage: '',
      imageLable: '',
      prevLabel: 'ooh 2',
      prevRoute: 'ooh-02',
      nextLabel: '',
      nextRoute: '',
      sections: [
        {
          key: '',
          title: 'The Long Hiss Exercise',
          icon: <MusicVideoIcon />,
          subtitle: 'Learning to Control Your Breath for Longer Phrases',
          markdown: `

Singing requires careful and precise control of your breathing, so it is essential to practice breath control often and with focus.
In this section, we will contintue to discuss proper breathing techique.

You cannot breath efficiently when you have tension in your body.
Your the expansion of your abdonmen should be down and outward from your core.
The may seem unnatrual at first since you may be used to taking deep breaths in your chest.
Instead, you maintain an expanded rib cage and don't let it collapse.

Open your mouth and take a deep inhale. Slowly release your breath as you count to ten. Don't strain on the inhale, but make sure it is deep enough.

Relax the abdomen so it can expand with your inhale.

Down and out feeling. Not a squeezing feeling.

Abdomen, ribs, back should all expand.

Place you hads on your belly to feel it expand.
Then place ands on the sides.
Then back.

chest breathing can be tense;
may feel deep but it is actually shallow

Pay attention to what muscles in your belly become engaged.

`,
          exerciseId: null,
          youTubeLink: null,
        },
      ],
    },
  ], // breath 2
])

export default courseContentMap

// PART OF THIS NEEDS TO BE PART OF THE HELP FOR THEPLAYER

// The major chord consists of the 1st, 3rd, and 5th notes of the major scale. In the beginning course, we will keep music theory the minimum that you need to know to understand the Exercises and perform them effectively. Later, we will start exploring more advanced music theory tops.

// For now, just try to start traing your ears to recognize the rising or falling sounds of the notes. These are referred to as intervals.

// When practicing scale Exercises in VocalBrain, there are a couple of things to keep in mind. First, often the chord and sometimes the root note of the scale are played at the beginning. You should be listening to the tones and qualities of the chord that is being played. Usually, you want to simply listen to the root note also. Then sing along with the scale and try to hear how close your voice matches what is being played.

// In many exercises, the chord and root not will be played on another staff. You will always sing the top staff.

// Also, the up and down arrows are used to adjust the key (using the Transpose arrows) of the scale. You should try to spend about 5-7 minutes on this exercise. The default Repetitions is merely a guide. Discover for yourself how many repetitions you should do per key in the time you have alloted. For example, if your vocal range covers 1.5 octaves, you may want to do 7-10 repetitions per key. If you have a 3 octave range, you may wish to only do 3 repetitions per key.

// This pattern of a chord plus the notes it is comprised of is used frequently in singing exercises.

// ## Always allow yourself the freedom to experiment!

//  [
//     'major-chord-01',
//     {
//       seriesKey: 'major-chord-01',
//       title: 'The Major Chord',
//       subtitle: '',
//       description: '',
//       mainImage: '',
//       imageLable: '',
//       prevLabel: 'Warming Up Your Body',
//       prevRoute: 'warmup-01',
//       nextLabel: 'Pitch Training',
//       nextRoute: 'pitch-training-01',
//       sections: [
//         {
//           key: 'key-sec-01',
//           title: 'The Major Chord',
//           icon: <MusicVideoIcon />,
//           subtitle: null,
//           markdown: `
// The Major Scale represents one of the most fundamental exercises for singers. 
// `,
//           exerciseId: 'major-chord-01',
//           youTubeLink: null,
//         },
//       ],
//     },
//   ], // end major chord