import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Typography,
  Container,
  Stepper,
  Step,
  StepLabel,
  StepContent,
} from '@mui/material'
import { useParams, useNavigate } from 'react-router-dom' // Import useParams and useNavigate from react-router-dom
import SoundSlicePlayer from '../../components/soundslice/SoundSlicePlayer'
import LatestLesson from './Streaks'

const Tutorial = () => {
  const [activeStep, setActiveStep] = useState(0)
  const [courseContent, setCourseContent] = useState([])
  const { tutorialId } = useParams() // Capture the URL parameter
  const navigate = useNavigate()

  useEffect(() => {
    // Dynamically import the course content based on the URL parameter
    import(`./${tutorialId}`)
      .then((module) => setCourseContent(module.default))
      .catch((err) => console.error('Failed to load course content', err))
  }, [tutorialId])

  const steps = courseContent.map((item) => item.title)

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleButtonAction = (url) => {
    if (url) {
      navigate(url)
    }
  }

  const renderContentItem = (item) => {
    switch (item.type) {
      case 'text':
        return (
          <Typography style={{ marginBottom: '1em' }}>
            {item.content}
          </Typography>
        )
      case 'slice':
        // WARN: THIS IS COMPLETE DUMMY DATA FOR TUTORIAL
        const sliceData = {
          id: '22fe2bc2-082a-426f-9314-309e6fb33b2c',
          variants: [
            { numId: 6, sliceId: 'gbJlc', type: 'low' },
            { numId: 7, sliceId: 'BH5lc', type: 'high' },
          ],
        }
        return (
          <Box style={{ marginBottom: '2em' }}>
            <Typography style={{ marginBottom: '1em' }}>
              {item.content}
            </Typography>
            <SoundSlicePlayer sliceData={sliceData} />
          </Box>
        )
      case 'streak':
        return <LatestLesson currentStreak={1} totalActivities={1} />
      case 'slice-alt':
        // WARN: THIS IS COMPLETE DUMMY DATA FOR TUTORIAL
        const sliceDataAlt = {
          id: '363ba79e-888d-4df8-a3bb-c51ad753ecca',
          variants: [
            { numId: 6, sliceId: 'SjFYc', type: 'low' },
            { numId: 7, sliceId: 'x47lc', type: 'high' },
          ],
        }
        return (
          <Box style={{ marginBottom: '2em' }}>
            <Typography style={{ marginBottom: '1em' }}>
              {item.content}
            </Typography>
            <SoundSlicePlayer sliceData={sliceDataAlt} />
          </Box>
        )
      case 'video':
        return (
          <iframe
            width='560'
            height='315'
            src={`https://www.youtube.com/embed/${item.id}`}
            title='YouTube video player'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen></iframe>
        )
      case 'trainer-1':
      case 'trainer-2':
        const src =
          item.type === 'trainer-1'
            ? 'http://localhost:8080/?sn=48&nk=6&et=123454321&tm=train'
            : 'http://localhost:8080/?sn=48&nk=6&et=123454321&tm=test'
        return (
          <Box style={{ marginBottom: '2em' }}>
            <Typography style={{ marginBottom: '1em' }}>
              {item.content}
            </Typography>
            <iframe
              src={src}
              width='100%'
              height='575px'
              allow='microphone; fullscreen;'
              frameBorder='0'
              style={{ border: '1px solid #ccc' }}></iframe>
          </Box>
        )
      case 'report':
      case 'breathing':
        const reportSrc =
          item.type === 'report'
            ? 'http://localhost:8080/report.html'
            : '/animation.html'
        return (
          <Box style={{ marginBottom: '2em' }}>
            <Typography style={{ marginBottom: '1em' }}>
              {item.content}
            </Typography>
            <iframe
              src={reportSrc}
              width='100%'
              height='600px'
              style={{ border: 'none', marginBottom: '2em' }}></iframe>
          </Box>
        )
      default:
        return <Typography>{item.content}</Typography>
    }
  }

  const finalStepConfig = courseContent.find((item) => item.type === 'buttons')
  const finalStepButtons = finalStepConfig
    ? finalStepConfig.finalStepButtons
    : []

  return (
    <Container>
      <Stepper activeStep={activeStep} orientation='vertical'>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              {renderContentItem(courseContent[index])}
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant='contained'
                    onClick={
                      activeStep === steps.length - 1
                        ? () => handleButtonAction(finalStepButtons[0]?.url)
                        : handleNext
                    }
                    sx={{ mt: 1, mr: 1 }}>
                    {activeStep === steps.length - 1
                      ? finalStepButtons[0]?.text
                      : 'Next'}
                  </Button>
                  {activeStep === steps.length - 1 &&
                    finalStepButtons[1]?.url && (
                      <Button
                        onClick={() =>
                          handleButtonAction(finalStepButtons[1]?.url)
                        }
                        sx={{ mt: 1, mr: 1 }}>
                        {finalStepButtons[1]?.text}
                      </Button>
                    )}
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}>
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Container>
  )
}

export default Tutorial
