import React, { useState, useEffect } from 'react'
import { getAuth } from 'firebase/auth'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Button,
  Container,
  Grid,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../firebase/config' // VERY IMPORTANT: Confirm correct path to firebase config

export default function Step4() {
  const auth = getAuth()
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    singingExperience: '',
  })

  useEffect(() => {
    async function fetchUserData() {
      if (!auth.currentUser?.uid) {
        console.log('AUTH_CURRENT_USER_UID_NIL', auth.currentUser)
        return
      }
      const userDoc = doc(db, 'users', auth.currentUser.uid)
      const userSnapshot = await getDoc(userDoc)
      if (userSnapshot.exists()) {
        setFormData((prevState) => ({
          ...prevState,
          singingExperience: userSnapshot.data().singingExperience || '',
        }))
      }
    }
    fetchUserData()
  }, [auth.currentUser])

  const onSingingExperienceChange = (event) => {
    setFormData({
      singingExperience: event.target.value,
    })
  }

  async function onSubmit() {
    try {
      const ref = doc(db, 'users', auth.currentUser.uid)
      await updateDoc(ref, {
        singingExperience: formData.singingExperience,
      })
      //toast.success('Singing experience updated')
      navigate('/intro/step-5') // VERY IMPORTANT: Adjust navigation path according to your routing configuration
    } catch (error) {
      toast.error('Could not update singing experience {users}.')
    }
  }

  const handleBack = () => {
    navigate(-1) // Navigate to the previous page
  }

  return (
    <React.Fragment>
      <Container maxWidth='md'>
        <Box my={4}>
          <form>
            <FormControl fullWidth margin='normal' variant='outlined'>
              <InputLabel id='singingExperienceLabel'>
                Your singing experience?
              </InputLabel>
              <Select
                labelId='singingExperienceLabel'
                id='singingExperience'
                value={formData.singingExperience}
                onChange={onSingingExperienceChange}
                label='Your singing experience?'>
                <MenuItem value='NEVER'>I've never sung before</MenuItem>
                <MenuItem value='BEGINNER'>Beginner</MenuItem>
                <MenuItem value='INTERMEDIATE'>Intermediate</MenuItem>
                <MenuItem value='ADVANCED'>Advanced</MenuItem>
              </Select>
            </FormControl>
            <Grid container spacing={2} justifyContent='space-between'>
              <Grid item>
                <Button color='primary' onClick={handleBack} variant='outlined'>
                  Back
                </Button>
              </Grid>
              <Grid item>
                <Button color='primary' onClick={onSubmit} variant='contained'>
                  Next
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </React.Fragment>
  )
}
