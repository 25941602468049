// Helpers.js

import { db } from '../../firebase/config'
import { collection, addDoc, serverTimestamp } from 'firebase/firestore'

export const trackActivity = async (userId, activity) => {
  try {
    await addDoc(collection(db, 'activities'), {
      userId,
      activity,
      timestamp: serverTimestamp(),
    })
    console.log("TRACK_ACTIVITY", userId, activity)
  } catch (error) {
    console.error('Error tracking activity: ', error)
  }
}


export function getUserStartNote(vocalRange) {
  if (vocalRange === 'HIGH') { //#TODO - get rid of this
    return 50;
  } else if ( vocalRange === 'LOW') {
    return 57;
  } else {
    return 50; // default
  }
}

export function buildTrainerUrl(data, vocalRange) {
  const exerciseType = data.exerciseType || '13531'
  const startNote = getUserStartNote(vocalRange, data)
  const playerSpeed = data.tr_speed ? data.tr_speed : 'normal'
  const vowel = data.vowel ? data.vowel : ``
  const trainerPlayToKey = data.trainerPlayToKey ? data.trainerPlayToKey : 1
  const baseUrl = 'https://vbp.vocalbrain.com/?'
  const retUrl = `${baseUrl}exercise_type=${exerciseType}&start_note=${startNote}&player_speed=${playerSpeed}&ptk=${trainerPlayToKey}&vowel=${vowel}`
  return retUrl
}

function stripBeforeSlash(str) {
  if (!str) {
    console.error('Input string is undefined')
    return ''
  }

  var parts = str.split('/')
  return parts[parts.length - 1]
}


export function buildOsmdUrl(params) {
  const { fileName, lowOctave, transpose, repetitions } = params;
  
  if (lowOctave === undefined) {
    console.error('lowOctave is undefined')
    throw Error('lowOctave is undefined')
  }
   if (transpose === undefined) {
     console.error('transpose is undefined')
     throw Error('transpose is undefined')
   }

  const fn = stripBeforeSlash(fileName) + (lowOctave ? '-8vb.musicxml' : '-0vb.musicxml')
  const retUrl = `https://vb-osmd.web.app/?embedded&openUrl=${fn}&endUrl&transposeParam=${transpose}&totalReps=${repetitions}&showZoomControl=0&zoom=0.5`
  console.log('OSMD_EMBED_URL', retUrl)
  return retUrl
}

export function keyLookup(kv) {
  const keyValue = Math.abs(kv % 12)
  if (keyValue === 0) {
    return 'C'
  } else if (keyValue === 1) {
    return 'D♭'
  } else if (keyValue === 2) {
    return 'D'
  } else if (keyValue === 3) {
    return 'E♭'
  } else if (keyValue === 4) {
    return 'E'
  } else if (keyValue === 5) {
    return 'F'
  } else if (keyValue === 6) {
    return 'G♭'
  } else if (keyValue === 7) {
    return 'G'
  } else if (keyValue === 8) {
    return 'A♭'
  } else if (keyValue === 9) {
    return 'A'
  } else if (keyValue === 10) {
    return 'B♭'
  } else if (keyValue === 11) {
    return 'B'
  } else {
    return 'ERR'
  }
  
}
