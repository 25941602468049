import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Button,
} from '@mui/material'
import {
  getStorage,
  ref,
  listAll,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
}))

const AudioTable = ({ studentId }) => {
  const [audioFiles, setAudioFiles] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  useEffect(() => {
    const fetchAudioFiles = async () => {
      const storage = getStorage()
      const audiosRef = ref(storage, 'audios')
      const res = await listAll(audiosRef)
      const wavFiles = res.items.filter(
        (item) => item.name.startsWith(studentId) && item.name.endsWith('.webm')
      )
      const filesWithUrls = await Promise.all(
        wavFiles.map(async (file) => {
          const url = await getDownloadURL(file)
          return { name: file.name, url }
        })
      )
      setAudioFiles(filesWithUrls)
    }

    fetchAudioFiles()
  }, [studentId])

  const handleDelete = async (fileName) => {
    const storage = getStorage()
    const fileRef = ref(storage, `audios/${fileName}`)
    try {
      await deleteObject(fileRef)
      setAudioFiles((prevFiles) =>
        prevFiles.filter((file) => file.name !== fileName)
      )
    } catch (error) {
      console.error('Error deleting file:', error)
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const formatDateFromFilename = (filename) => {
    const timestampMatch = filename.match(/(\d{8}_\d{6})/)
    if (!timestampMatch) return 'Unknown Date'

    const timestamp = timestampMatch[0]
    const date = new Date(
      parseInt(timestamp.substring(0, 4), 10),
      parseInt(timestamp.substring(4, 6), 10) - 1,
      parseInt(timestamp.substring(6, 8), 10),
      parseInt(timestamp.substring(9, 11), 10),
      parseInt(timestamp.substring(11, 13), 10),
      parseInt(timestamp.substring(13, 15), 10)
    )
    return date.toLocaleString()
  }

  return (
    <Box
      sx={{
        p: 1,
        bgcolor: 'background.default',
        display: 'grid',
        gap: 1,
      }}>
      <Box paddingLeft={2} paddingRight={2} paddingBottom={1}>
        <Typography variant='subtitle1' component='h2'>
          Audio Recordings
        </Typography>
      </Box>
      <Box
        paddingX={2}
        paddingBottom={2}
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Audio</StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {audioFiles
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((audioFile) => (
                  <TableRow key={audioFile.name}>
                    <TableCell>
                      {formatDateFromFilename(audioFile.name)}
                    </TableCell>
                    <TableCell>
                      <audio controls style={{ marginLeft: '10px' }}>
                        <source src={audioFile.url} type='audio/wav' />
                        Your browser does not support the audio element.
                      </audio>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        <Button
                          variant='contained'
                          color='primary'
                          href={audioFile.url}
                          download>
                          Download
                        </Button>
                        <Button
                          variant='contained'
                          color='error'
                          onClick={() => handleDelete(audioFile.name)}>
                          Delete
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={audioFiles.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>
    </Box>
  )
}

export default AudioTable
