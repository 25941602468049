import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { db } from '../../firebase/config'
import {
  collection,
  addDoc,
  doc,
  updateDoc,
  getDoc,
  getDocs,
  deleteDoc,
  writeBatch,
} from 'firebase/firestore'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { toast } from 'react-toastify'
import CustomSliceModal from './CustomSliceModal'
import CustomVideoModal from './CustomVideoModal'
import CustomTextModal from './CustomTextModal' // New modal for adding text
import { useSession } from '../../UserProvider'
import './LessonEditOrCreate.css'

export default function LessonEditOrCreate() {
  const { id } = useParams()
  const navigate = useNavigate()
  const { user, isAppAdmin, isCoach } = useSession()
  const [uidForNewLesson, setUidForNewLesson] = useState('')
  const [lessonTitle, setLessonTitle] = useState('')
  const [lessonNotes, setLessonNotes] = useState('')
  const [isSliceModalOpen, setIsSliceModalOpen] = useState(false)
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false)
  const [isTextModalOpen, setIsTextModalOpen] = useState(false) // State for text modal
  const [currentLessonId, setCurrentLessonId] = useState(id || '')
  const [lessonSlices, setLessonSlices] = useState([])
  const [maxOrder, setMaxOrder] = useState(0)
  const [editableSlice, setEditableSlice] = useState(null) // State to hold slice data for editing
  const [editableVideo, setEditableVideo] = useState(null) // State to hold video data for editing
  const [editableText, setEditableText] = useState(null) // State to hold text data for editing

  useEffect(() => {
    if (localStorage.getItem('studentId')) {
      setUidForNewLesson(localStorage.getItem('studentId'))
    } else {
      setUidForNewLesson(user.uid)
    }
  }, [user.uid])

  useEffect(() => {
    const fetchLessonAndSlices = async () => {
      if (currentLessonId) {
        const docRef = doc(db, 'lessons', currentLessonId)
        const docSnap = await getDoc(docRef)
        if (
          docSnap.exists() &&
          (docSnap.data().userId === user.uid || isAppAdmin || isCoach)
        ) {
          const data = docSnap.data()
          setLessonTitle(data.title)
          setLessonNotes(data.notes || '')
          await fetchLessonSlices()
        } else {
          toast.error('Lesson does not exist or you do not have permission.')
        }
      }
    }

    fetchLessonAndSlices()
  }, [currentLessonId, user.uid, isAppAdmin, isCoach])

  const fetchLessonSlices = async () => {
    if (currentLessonId) {
      const slicesSnapshot = await getDocs(
        collection(db, `lessons/${currentLessonId}/slices`)
      )
      let highestOrder = 0
      const slices = slicesSnapshot.docs
        .map((docSnap) => {
          const slice = { id: docSnap.id, ...docSnap.data() }
          highestOrder = Math.max(highestOrder, slice.order || 0)
          return slice
        })
        .sort((a, b) => a.order - b.order)
      setLessonSlices(slices)
      setMaxOrder(highestOrder)
    }
  }

  const handleAutoSaveLesson = async () => {
    if (!lessonTitle.trim() || !user) {
      return
    }

    const lessonData = {
      title: lessonTitle,
      notes: lessonNotes,
      userId: uidForNewLesson,
    }

    if (currentLessonId) {
      const docRef = doc(db, 'lessons', currentLessonId)
      await updateDoc(docRef, lessonData)
      toast.success('Lesson saved.')
    } else {
      const currentDate = new Date().toLocaleDateString()
      const docRef = await addDoc(collection(db, 'lessons'), {
        title: `New Workout - ${currentDate}`,
        notes: lessonNotes || 'No notes provided',
        userId: uidForNewLesson,
        createdAt: new Date(),
      })
      setCurrentLessonId(docRef.id)
    }
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (currentLessonId) {
        handleAutoSaveLesson()
      }
    }, 2000)

    return () => clearTimeout(delayDebounceFn)
  }, [lessonTitle, lessonNotes, user.uid])

  const handleRemoveSlice = async (sliceId) => {
    if (currentLessonId && sliceId) {
      await deleteDoc(doc(db, `lessons/${currentLessonId}/slices`, sliceId))
      await fetchLessonSlices()
      toast.success('Exercise removed successfully!')
    }
  }

  const handleEditSlice = (slice) => {
    if (slice.type === 'video') {
      setEditableVideo(slice)
      setIsVideoModalOpen(true)
    } else if (slice.type === 'text') {
      setEditableText(slice)
      setIsTextModalOpen(true)
    } else {
      setEditableSlice(slice)
      setIsSliceModalOpen(true)
    }
  }

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return
    }

    const items = Array.from(lessonSlices)
    const sourceIndex = result.source.index
    const destinationIndex = result.destination.index
    const [reorderedItem] = items.splice(sourceIndex, 1)
    items.splice(destinationIndex, 0, reorderedItem)

    const updatedItems = items.map((item, index) => ({ ...item, order: index }))
    setLessonSlices(updatedItems)

    const batch = writeBatch(db)
    updatedItems.forEach((slice, index) => {
      const sliceRef = doc(db, `lessons/${currentLessonId}/slices`, slice.id)
      batch.update(sliceRef, { order: index })
    })

    await batch.commit()
  }

  const sendEmailReport = async (
    toEmail,
    subject,
    htmlContent,
    textContent
  ) => {
    try {
      const emailMessage = {
        message: {
          html: htmlContent,
          subject: subject,
          text: textContent,
        },
        to: toEmail,
      }
      await addDoc(collection(db, 'mail'), emailMessage)
      toast.success('Email report sent successfully!')
    } catch (error) {
      toast.error(`Error sending email report: ${error.message}`)
    }
  }

  return (
    <div className='create-form'>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='droppable-slices'>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {localStorage.getItem('studentId') ? (
                <h4>{localStorage.getItem('studentName')}</h4>
              ) : null}
              <h4>{currentLessonId ? 'Edit Workout' : 'Create New Workout'}</h4>
              <input
                type='text'
                value={lessonTitle}
                onChange={(e) => setLessonTitle(e.target.value)}
                placeholder='Lesson Title'
                className='input-title'
              />
              <textarea
                rows={4}
                value={lessonNotes}
                onChange={(e) => setLessonNotes(e.target.value)}
                placeholder='Notes'
                className='textarea-notes'
              />
              <div style={{ display: 'flex', gap: '10px' }}>
                <button
                  onClick={() => {
                    setEditableSlice(null) // Reset editable slice to null for adding new slice
                    setIsSliceModalOpen(true)
                  }}
                  disabled={!currentLessonId || !user}
                  className='button-add-slice'
                  style={{ flex: 1 }}>
                  Add Exercise
                </button>
                <button
                  onClick={() => {
                    setEditableVideo(null) // Reset editable video to null for adding new video
                    setIsVideoModalOpen(true)
                  }} // Open video modal
                  disabled={!currentLessonId || !user}
                  className='button-add-slice'
                  style={{ flex: 1 }}>
                  Add Video
                </button>
                <button
                  onClick={() => {
                    setEditableText(null) // Reset editable text to null for adding new text
                    setIsTextModalOpen(true)
                  }} // Open text modal
                  disabled={!currentLessonId || !user}
                  className='button-add-slice'
                  style={{ flex: 1 }}>
                  Add Text
                </button>
              </div>

              {currentLessonId && (
                <CustomSliceModal
                  open={isSliceModalOpen}
                  onClose={() => setIsSliceModalOpen(false)}
                  lessonId={currentLessonId}
                  onSliceAdded={fetchLessonSlices}
                  maxOrder={maxOrder}
                  editableSlice={editableSlice} // Pass editable slice for editing
                />
              )}
              {currentLessonId && (
                <CustomVideoModal
                  open={isVideoModalOpen}
                  onClose={() => setIsVideoModalOpen(false)}
                  lessonId={currentLessonId}
                  onVideoAdded={fetchLessonSlices} // Fetch slices after adding video
                  maxOrder={maxOrder}
                  editableVideo={editableVideo} // Pass editable video for editing
                />
              )}
              {currentLessonId && (
                <CustomTextModal
                  open={isTextModalOpen}
                  onClose={() => setIsTextModalOpen(false)}
                  lessonId={currentLessonId}
                  onTextAdded={fetchLessonSlices} // Fetch slices after adding text
                  maxOrder={maxOrder}
                  editableText={editableText} // Pass editable text for editing
                />
              )}
              {lessonSlices.map((slice, index) => (
                <Draggable key={slice.id} draggableId={slice.id} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className='draggable-slice'>
                      <span className='slice-name'>{slice.name}</span>
                      <div className='button-group'>
                        <button
                          onClick={() => handleEditSlice(slice)}
                          className='button-edit-slice'>
                          Edit
                        </button>
                        <button
                          onClick={() => handleRemoveSlice(slice.id)}
                          className='button-remove-slice'>
                          Remove
                        </button>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '10px',
          marginTop: '20px',
        }}>
        <button
          onClick={() => navigate(`/student/${uidForNewLesson}`)}
          className='button-done'>
          Done
        </button>
        <button
          onClick={() => navigate(`/lesson/${currentLessonId}`)}
          className='button-done'>
          View
        </button>
        {isCoach && 
        <button
          onClick={async () => {
            const toEmail = localStorage.getItem('studentEmail') // Ensure this is set correctly
            const studentName = localStorage.getItem('studentName') // Ensure this is set correctly
            const subject = 'Your Lesson Report'
            const textContent = `Hi ${studentName},

Here are the notes for your lesson:

${lessonNotes}

Lesson Details:

${lessonSlices
  .map(
    (slice) => `
  Exercise: ${slice.name}
  Description: ${slice.description || 'No description provided'}
  Duration: ${slice.duration || 'Not specified'}
`
  )
  .join('\n')}

Best regards,
Your Vocal Coach`

            const htmlContent = `
              <p>Hi ${studentName},</p>
              <p>Here are the notes for your lesson:</p>
              <p>${lessonNotes}</p>
              <table style="width: 100%; border-collapse: collapse;">
                <thead>
                  <tr>
                    <th style="border: 1px solid #ddd; padding: 8px;">Exercise</th>
                  </tr>
                </thead>
                <tbody>
                  ${lessonSlices
                    .map(
                      (slice) => `
                    <tr>
                      <td style="border: 1px solid #ddd; padding: 8px;">${
                        slice.name
                      }</td>
                    </tr>
                  `
                    )
                    .join('')}
                </tbody>
              </table>
              <p>Best regards,<br/>Your Vocal Coach</p>`

            await sendEmailReport(toEmail, subject, htmlContent, textContent)
          }}
          className='button-done'>
          Send to Student
        </button>}
      </div>
    </div>
  )
}
