import React from 'react'
import { Link } from 'react-router-dom'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import DashboardIcon from '@mui/icons-material/Dashboard'
import HomeIcon from '@mui/icons-material/Home'
import AutoGraphIcon from '@mui/icons-material/AutoGraph'
import AccountCircle from '@mui/icons-material/AccountCircle'
import ModelTrainingIcon from '@mui/icons-material/ModelTraining'
import RecentActorsIcon from '@mui/icons-material/RecentActors'
import { useSession } from '../../UserProvider'


const linkConfig = [
  {
    text: 'Coach',
    to: '/students',
    icon: <RecentActorsIcon />,
    premium: false,
    adminOnly: true,
  },
  { text: 'Home', to: '/', icon: <HomeIcon />, premium: false },
  { text: 'Workouts', to: '/student', icon: <DashboardIcon />, premium: false },
  //{ text: 'Tools', to: '/trainers', icon: <AutoGraphIcon />, premium: false },
  // {
  //   text: 'Course',
  //   to: '/course/home',
  //   icon: <SchoolIcon />,
  //   premium: false,
  // },
  {
    text: 'Exercises',
    to: '/slices',
    icon: <ModelTrainingIcon />,
    premium: true,
  },
  {
    text: 'Account',
    to: '/profile',
    icon: <AccountCircle />,
    premium: false,
  },
  // { text: 'Help', to: '/guide', icon: <Help />, premium: false },
]

function DrawerLinkItem({ linkText, linkTo, icon }) {
  return (
    <ListItemButton
      style={{ padding: '10px', width: '100%', boxSizing: 'border-box' }}>
      <Link
        to={linkTo}
        style={{
          textDecoration: 'none',
          color: 'inherit',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
        }}>
        <ListItemIcon style={{ minWidth: '40px' }}>{icon}</ListItemIcon>
        <ListItemText
          primary={linkText}
          style={{
            marginLeft: '10px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        />
      </Link>
    </ListItemButton>
  )
}

function DrawerLinks() {
  const { isAppAdmin, isCoach } = useSession()




  return (
    <React.Fragment>
      {linkConfig.map((link) => {
        // if (link.premium && !isPremium) return null
        if (link.adminOnly && !isAppAdmin && !isCoach) return null
        return (
          <DrawerLinkItem
            key={link.text}
            linkText={link.text}
            linkTo={link.to}
            icon={link.icon}
          />
        )
      })}
    </React.Fragment>
  )
}

export default DrawerLinks
