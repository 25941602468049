import React from 'react'
import { getAuth } from 'firebase/auth'
import { updateDoc, doc } from 'firebase/firestore'
import { db } from '../../firebase/config' // VERY IMPORTANT: Confirm correct path to firebase config
import { useNavigate } from 'react-router-dom'
import { Button, Container, Typography, Box } from '@mui/material'
import { toast } from 'react-toastify'

export default function FinalStep() {
  const auth = getAuth()
  const navigate = useNavigate()

  async function completeIntroduction() {
    try {
      const ref = doc(db, 'users', auth.currentUser.uid)
      await updateDoc(ref, {
        introComplete: true,
      })
      // Navigate to home page or dashboard after completion
      navigate('/tutorial/intro') // VERY IMPORTANT: Adjust navigation path according to your routing configuration
    } catch (error) {
      toast.error('Could not complete the introduction process.')
    }
  }

  return (
    <Container maxWidth='sm' style={{ textAlign: 'center', marginTop: '2em' }}>
      <Typography variant='h5' gutterBottom>
        Let's get started!
      </Typography>
      <Typography variant='body1' gutterBottom>
        Using this exercise we'll master the cornerstone of singing: breath
        control.
      </Typography>
      <Box marginTop='2em'>
        <Button variant='outlined' onClick={completeIntroduction}>
          Continue
        </Button>
      </Box>
    </Container>
  )
}
