// Comment.js
import React from 'react';
import { Typography, Box } from '@mui/material';

const Comment = ({ commentText, commenterName, date }) => {
  const formattedDate = date ? date.toLocaleDateString() : 'Unknown date';

  return (
    <Box mt={2}>
      <Typography variant="subtitle1">
        {commenterName} ({formattedDate})
      </Typography>
      <Typography variant="body1">{commentText}</Typography>
    </Box>
  );
};

export default Comment;
