import React, { useState, useEffect } from 'react'
import { getAuth, updateProfile } from 'firebase/auth'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Button,
  Container,
  Grid,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../firebase/config' // VERY IMPORTANT: Confirm correct path to firebase config

export default function Step3() {
  const auth = getAuth()
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    ageGroup: '',
  })

  useEffect(() => {
    async function fetchUserData() {
      if (!auth.currentUser?.uid) {
        console.log('AUTH_CURRENT_USER_UID_NIL', auth.currentUser)
        return
      }
      const userDoc = doc(db, 'users', auth.currentUser.uid)
      const userSnapshot = await getDoc(userDoc)
      if (userSnapshot.exists()) {
        setFormData((prevState) => ({
          ...prevState,
          ageGroup: userSnapshot.data().ageGroup || '',
        }))
      }
    }
    fetchUserData()
  }, [auth.currentUser])

  const onAgeGroupChange = (event) => {
    setFormData({
      ageGroup: event.target.value,
    })
  }

  async function onSubmit() {
    try {
      const ref = doc(db, 'users', auth.currentUser.uid)
      await updateDoc(ref, {
        ageGroup: formData.ageGroup,
      })
      //toast.success('User age group updated')
      navigate('/intro/step-4') // VERY IMPORTANT: Adjust navigation path according to your routing configuration
    } catch (error) {
      toast.error('Could not update user age group {users}.')
    }
  }

  const handleBack = () => {
    navigate(-1) // Navigate to the previous page in the history stack
  }

  return (
    <React.Fragment>
      <Container maxWidth='md'>
        <Box my={4}>
          <form>
            <FormControl fullWidth margin='normal' variant='outlined'>
              <InputLabel id='ageGroupLabel'>
                What is your age group?
              </InputLabel>
              <Select
                labelId='ageGroupLabel'
                id='ageGroup'
                value={formData.ageGroup}
                onChange={onAgeGroupChange}
                label='What is your age group?'>
                <MenuItem value='U13'>Under 13</MenuItem>
                <MenuItem value='A14-17'>14-17</MenuItem>
                <MenuItem value='A18-24'>18-24</MenuItem>
                <MenuItem value='A25-34'>25-34</MenuItem>
                <MenuItem value='A35-44'>35-44</MenuItem>
                <MenuItem value='A45-54'>45-54</MenuItem>
                <MenuItem value='A55-64'>55-64</MenuItem>
                <MenuItem value='A65'>65 and over</MenuItem>
              </Select>
            </FormControl>
            <Grid container spacing={2} justifyContent='space-between'>
              <Grid item>
                <Button color='primary' onClick={handleBack} variant='outlined'>
                  Back
                </Button>
              </Grid>
              <Grid item>
                <Button color='primary' onClick={onSubmit} variant='contained'>
                  Next
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </React.Fragment>
  )
}
