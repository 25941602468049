import React, { useState, useEffect } from 'react'
import { Grid, Box, CircularProgress, Divider } from '@mui/material'
import DashboardCard from './DashboardCard'
import { useSession } from '../../UserProvider'
import {
  getLatestLessonForUser,
  fetchUserActivities,
} from '../../firebase/dataHelper'
import Streaks from './Streaks'
import Quote from './Quote'
import StudentDashboard from '../students/StudentDashboard'
import ReportsViewer from './ReportsViewer'
import {
  computeTotalActivities,
  computeCurrentStreak,
  computeLongestStreak,
} from './streakHelpers' 

const Dashboard = () => {
  const session = useSession()
  const [userId, setUserId] = useState('')
  const { isPremium, isAppAdmin } = session
  const [loading, setLoading] = useState(true)
  const [currentStreak, setCurrentStreak] = useState(0)
  const [longestStreak, setLongestStreak] = useState(0)
  const [totalActivities, setTotalActivities] = useState(0)
  const [latestLesson, setLatestLesson] = useState(null)

  useEffect(() => {
    if (session.user) {
      const uid = session.user.uid
      setUserId(uid)

      const loadUserActivities = async () => {
        setLoading(true)
        const activities = await fetchUserActivities(uid)
        setCurrentStreak(computeCurrentStreak(activities))
        setLongestStreak(computeLongestStreak(activities))
        setTotalActivities(computeTotalActivities(activities))
        setLoading(false)
      }

      if (uid) {
        loadUserActivities()
      }

      const fetchLatestLesson = async () => {
        const lesson = await getLatestLessonForUser(uid)
        setLatestLesson(lesson)
      }

      fetchLatestLesson()
    }
  }, [session.user, userId])

  if (loading) {
    return <CircularProgress />
  }

  if (isAppAdmin) {
    return <StudentDashboard />
  }

  return (
    <Box paddingLeft={2} paddingRight={2} paddingBottom={1} paddingTop={2}>
      <Grid container spacing={2}>
        <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
          <Streaks
            currentStreak={currentStreak}
            longestStreak={longestStreak}
            totalActivities={totalActivities}
          />
        </Grid>

        {latestLesson && (
          <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
            <DashboardCard id={'latestLesson'} latestLesson={latestLesson} />
          </Grid>
        )}

        <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
          <Quote />
        </Grid>

        <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
          <DashboardCard id={'course'} />
        </Grid>

        <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
          <DashboardCard id={'trainers'} />
        </Grid>
        <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
          <DashboardCard id={'exercises'} />
        </Grid>
        <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
          <DashboardCard id={'guide'} />
        </Grid>
        {userId === 'rAWcSzxKPGQbymbh5YIlNU34Meh2' && (
          <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
            <ReportsViewer studentId={userId} />
          </Grid>
        )}
      </Grid>
      <Divider sx={{ my: 1 }} />
    </Box>
  )
}

export default Dashboard
