import * as React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

// import { ThemeProvider } from '@emotion/react'
import { ThemeProvider } from '@mui/material/styles'
import App from './App'
import theme from './theme'
import UserProvider from './UserProvider'
import './index.css' // @MRR => Imported for supporting temp styles from example code. SHOULD GO AWAY SOMEDAY

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)
// Theme https://youtube.com/clip/UgkxWl7b9P2B693_ORW4roTqhHZ3aIDpRi02

// const darkTheme = createTheme({ palette: { mode: 'dark' } })
// const lightTheme = createTheme({ palette: { mode: 'light' } })

root.render(
  <UserProvider>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </UserProvider>
)
