import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Link } from 'react-router-dom'
import { ListItemText } from '@mui/material'

const ALLOWED_LABELS = ['Chest', 'Mix', 'Head', 'Breath']

export default function SliceTable(props) {
  const { data, title } = props

  const displayData = data.filter((el) =>
    el.labels.some((label) => ALLOWED_LABELS.includes(label))
  )

  displayData.map((row) => console.log(row))

  return (
    <React.Fragment>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Focus</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {displayData.map((row) => (
            <TableRow key={row.id}>
              <TableCell>
                <Link to={`/slice/${row.id}`}>
                  <ListItemText primary={row.name} />
                </Link>
              </TableCell>
              <TableCell>{row.labels.join(', ')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  )
}
