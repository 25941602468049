import React from 'react'
import { Box, Typography, Card, CardContent, Button } from '@mui/material'
import { Link } from 'react-router-dom'
import { cardContentMap } from './cardContentMap'
import { format } from 'date-fns'

function adjustTextLength(target, sample, maxLength) {
  maxLength = maxLength || sample.length
  return target.length > maxLength
    ? target.substring(0, maxLength - 3) + '...'
    : target
}

function DashboardCard(props) {
  const { id, hideLink, latestLesson } = props
  const buttonStyle = {
    textTransform: 'none',
    fontSize: '0.875rem',
    width: 'auto',
    padding: '6px 16px',
  }
  const descriptionBoxStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '50px', // Set a fixed height to match the size of the sample text box
  }

  if (id === 'latestLesson' && latestLesson) {
    const adjustedNotes = adjustTextLength(latestLesson.notes, '', 100)
    return (
      <Card
        sx={{
          borderRadius: '20px',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          backgroundColor: 'background.paper',
          color: 'text.primary',
          position: 'relative',
          overflow: 'hidden',
          maxWidth: 345,
          margin: 'auto',
          '&:hover': { boxShadow: '0px 8px 40px rgba(0, 0, 0, 0.2)' },
          '@media (max-width: 600px)': { maxWidth: '100%' },
        }}>
        <CardContent style={{ padding: '16px' }}>
          <Typography
            variant='h5'
            component='div'
            sx={{ fontWeight: 'bold', mb: 1 }}>
            {latestLesson.title}
          </Typography>
          <Typography variant='subtitle2' component='h3' sx={{ mb: 1 }}>
            New: {format(latestLesson.createdAt.toDate(), 'PPPpp')}
          </Typography>
          <Box style={descriptionBoxStyle}>
            <Typography variant='body2'>{adjustedNotes}</Typography>
          </Box>
          <Box textAlign='center' sx={{ mt: 2 }}>
            <Button
              variant='contained'
              size='medium'
              href={`/lesson/${latestLesson.id}`}
              sx={buttonStyle}>
              Start Workout
            </Button>
          </Box>
        </CardContent>
      </Card>
    )
  } else {
    const cardContent = cardContentMap.get(id)
    const adjustedDescription = adjustTextLength(
      cardContent.description,
      'Engage with comprehensive courses to systematically improve your singing.',
      100
    )
    return (
      <Card
        sx={{
          borderRadius: '20px',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          backgroundColor: 'background.paper',
          color: 'text.primary',
          position: 'relative',
          overflow: 'hidden',
          maxWidth: 345,
          margin: 'auto',
          '&:hover': { boxShadow: '0px 8px 40px rgba(0, 0, 0, 0.2)' },
          '@media (max-width: 600px)': { maxWidth: '100%' },
        }}>
        <CardContent style={{ padding: '16px' }}>
          <Typography
            variant='h5'
            component='div'
            sx={{ fontWeight: 'bold', mb: 1 }}>
            {cardContent.title}
          </Typography>
          <Typography variant='subtitle2' component='h3' sx={{ mb: 1 }}>
            {cardContent.subtitle}
          </Typography>
          <Box style={descriptionBoxStyle}>
            <Typography variant='body2'>{adjustedDescription}</Typography>
          </Box>
          {!hideLink && (
            <Box textAlign='center' sx={{ mt: 2 }}>
              <Link to={cardContent.linkTo} style={{ textDecoration: 'none' }}>
                <Button variant='contained' size='medium' sx={buttonStyle}>
                  {cardContent.linkText}
                </Button>
              </Link>
            </Box>
          )}
        </CardContent>
      </Card>
    )
  }
}

export default DashboardCard
