import { firebaseConfig } from '../../firebase/config'
import React, { useState, useRef } from 'react'
import { getStorage, ref, uploadBytes } from 'firebase/storage'
import { initializeApp } from 'firebase/app'
import { Button, Box } from '@mui/material'
import { useSession } from '../../UserProvider'

const app = initializeApp(firebaseConfig)
const storage = getStorage(app)

function addTimestamp(username) {
  const now = new Date()
  const timestamp = `_${now.getUTCFullYear()}${(now.getUTCMonth() + 1)
    .toString()
    .padStart(2, '0')}${now.getUTCDate().toString().padStart(2, '0')}_${now
    .getUTCHours()
    .toString()
    .padStart(2, '0')}${now.getUTCMinutes().toString().padStart(2, '0')}${now
    .getUTCSeconds()
    .toString()
    .padStart(2, '0')}`
  return username + '_' + timestamp
}

const AudioRecorder = () => {
  const [recorder, setRecorder] = useState(null)
  const [isRecording, setIsRecording] = useState(false)
  const chunksRef = useRef([])
  const { user } = useSession()

  const startRecording = () => {
    chunksRef.current = []
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(function (stream) {
        const newRecorder = new MediaRecorder(stream)
        newRecorder.start()
        newRecorder.ondataavailable = function (e) {
          chunksRef.current.push(e.data)
        }

        newRecorder.onstop = async function (e) {
          const blob = new Blob(chunksRef.current, { type: 'audio/webm' })
          // const audioURL = URL.createObjectURL(blob)
          const filename = addTimestamp(user.uid)
          const uploadTask = ref(storage, `audios/${filename}.webm`)
          await uploadBytes(uploadTask, blob)
          console.log('Upload completed successfully.')
          setIsRecording(false)
        }

        setRecorder(newRecorder)
        setIsRecording(true)
      })
  }

  const stopRecording = () => {
    if (recorder) {
      recorder.stop()
    }
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
      <Button
        variant='contained'
        color={isRecording ? 'secondary' : 'primary'}
        onClick={isRecording ? stopRecording : startRecording}>
        {isRecording ? 'STOP' : 'RECORD'}
      </Button>
    </Box>
  )
}

export default AudioRecorder
