import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { getDoc, doc, updateDoc } from 'firebase/firestore'
import { db } from '../../firebase/config'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Button,
  Container,
  Typography,
} from '@mui/material'
import { toast } from 'react-toastify'
import { useSession } from '../../UserProvider'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import AudioTable from './AudioTable'

export default function StudentCoachView() {
  const { studentId } = useParams()
  const navigate = useNavigate()
  const [vocalRange, setVocalRange] = useState('')
  const [coachsNotes, setCoachsNotes] = useState('')
  const [studentName, setStudentName] = useState('')
  const [studentEmail, setStudentEmail] = useState('')
  const session = useSession()
  const { isAppAdmin, isCoach } = session

  useEffect(() => {
    if (!isAppAdmin && !isCoach) {
      toast.error('Unauthorized access.')
      return
    }

    const fetchUserData = async () => {
      const userDoc = doc(db, 'users', studentId)
      const userSnapshot = await getDoc(userDoc)
      const d = userSnapshot.data()
      if (userSnapshot.exists()) {
        setVocalRange(d.vocalRange || '')
        setCoachsNotes(d.coachsNotes || '')
        setStudentName(d.firstName + ' ' + d.lastName)
        setStudentEmail(d.email)
      } else {
        toast.error('User does not exist.')
      }
    }

    fetchUserData()
  }, [studentId, isAppAdmin, isCoach])

  const handleVocalRangeChange = async (event) => {
    const newVocalRange = event.target.value
    setVocalRange(newVocalRange)

    if (isAppAdmin || isCoach) {
      try {
        const userDoc = doc(db, 'users', studentId)
        await updateDoc(userDoc, { vocalRange: newVocalRange })
        toast.success('Vocal range updated successfully.')
        localStorage.setItem('studentVocalRange', newVocalRange)
      } catch (error) {
        toast.error('Failed to update vocal range.')
      }
    }
  }

  const handleCoachsNotesChange = async (value) => {
    setCoachsNotes(value)

    if (isAppAdmin || isCoach) {
      try {
        const userDoc = doc(db, 'users', studentId)
        await updateDoc(userDoc, { coachsNotes: value })
      } catch (error) {
        toast.error("Failed to update coach's notes.")
      }
    }
  }

  const handleDone = () => {
    navigate('/student')
  }

  if (!isAppAdmin && !isCoach) {
    return <Box>Access Denied</Box>
  }

  return (
    <Container maxWidth='md'>
      <Box
        sx={{
          p: 2,
          bgcolor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          width: '100%',
        }}>
        <Typography
          variant='h4'
          sx={{ mb: 4, textAlign: 'center', width: '100%' }}>
          Student: {studentName}
        </Typography>
        <FormControl fullWidth margin='normal' variant='outlined'>
          <InputLabel id='vocalRangeLabel'>Vocal Range</InputLabel>
          <Select
            labelId='vocalRangeLabel'
            id='vocalRange'
            value={vocalRange}
            onChange={handleVocalRangeChange}
            label='Vocal Range'
            sx={{ width: '100%' }}>
            <MenuItem value='LOW'>Low Voice</MenuItem>
            <MenuItem value='HIGH'>High Voice</MenuItem>
          </Select>
        </FormControl>
        <Box sx={{ width: '100%', marginTop: 2 }}>
          <ReactQuill
            theme='snow'
            value={coachsNotes}
            onChange={handleCoachsNotesChange}
            style={{ height: '200px', width: '100%' }}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
          <Button
            variant='contained'
            onClick={handleDone}
            sx={{
              maxWidth: '200px',
              width: '100%',
              marginTop: '16px', // Add some margin to ensure no overlap
              alignSelf: 'center',
            }}>
            Done
          </Button>
        </Box>
        <Box sx={{ width: '100%', marginTop: 2 }}>
          <AudioTable studentId={studentId} />
        </Box>
      </Box>
    </Container>
  )
}
