import React, { useEffect, useState } from 'react'
import { Box, Paper, TextField } from '@mui/material'
import SliceTable from './SliceTable'
import { db } from '../../firebase/config'
import { collection, getDocs, query, orderBy } from 'firebase/firestore'
import { useSession } from '../../UserProvider'
import { useNavigate } from 'react-router-dom' // Import useNavigate for redirection

function Slices() {
  const [data, setData] = useState([])
  const [isPending, setIsPending] = useState(false)
  const [error, setError] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const { isPremium, isAppAdmin, isCoach } = useSession()
  const navigate = useNavigate() // Initialize useNavigate

  useEffect(() => {
    const fetchData = async () => {
      setIsPending(true)
      const slicesQuery = query(collection(db, 'slices'), orderBy('name'))
      const snap = await getDocs(slicesQuery)

      if (snap.empty) {
        setError('No Slices to load.')
        setIsPending(false)
      } else {
        setData(snap.docs.map((doc) => ({ id: doc.id, ...doc.data() })))
        setIsPending(false)
      }
    }

    if (!isPremium && !isAppAdmin && !isCoach) {
      navigate('/plan') // Redirect to /plan if the user is not premium or an admin
    } else {
      fetchData()
    }
  }, [isPremium, isAppAdmin, navigate])

  const filteredData = data.filter((slice) => {
    const nameMatch = slice.name
      ? slice.name.toLowerCase().includes(searchTerm.toLowerCase())
      : false
    const notesMatch = slice.notes
      ? slice.notes.toLowerCase().includes(searchTerm.toLowerCase())
      : false
    const labelsMatch = slice.labels.some((label) =>
      label.toLowerCase().includes(searchTerm.toLowerCase())
    )
    return nameMatch || notesMatch || labelsMatch
  })

  return (
    <Box paddingLeft={2} paddingRight={2} paddingBottom={1}>
      {/**<ResponsiveHeading
        icon={<FitnessCenterIcon />}
        title='Slices'
        description=''
      />*/}
      <Box
        sx={{ p: 1, bgcolor: 'background.default', display: 'grid', gap: 1 }}>
        <Paper elevation={6}>
          <Box paddingX={2} paddingBottom={2}>
            <TextField
              label='Search Exercises'
              variant='outlined'
              fullWidth
              margin='normal'
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <SliceTable data={filteredData} />
          </Box>
        </Paper>
      </Box>
    </Box>
  )
}

export default Slices
