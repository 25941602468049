import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
} from '@mui/material'
import { db } from '../../firebase/config'
import { collection, query, getDocs } from 'firebase/firestore'
import { useSession } from '../../UserProvider' // Import useSession

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
}))

const StudentTable = () => {
  const [users, setUsers] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const navigate = useNavigate()
  const session = useSession()
  const { user } = session
  const { isAppAdmin, isCoach } = session

  useEffect(() => {
    if (isCoach) {
      localStorage.removeItem('studentId')
      localStorage.removeItem('studentName')
      localStorage.removeItem('studentVocalRange')
      localStorage.removeItem('studentEmail')
    }
  }, [])

  useEffect(() => {
    const fetchUsers = async () => {
      const querySnapshot = await getDocs(collection(db, 'users'))
      let usersData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))

      // Filtering logic based on user roles
      if (isAppAdmin) {
        setUsers(usersData)
      } else if (isCoach) {
        // Filter to show only users where the coachId matches the logged-in user's ID
        usersData = usersData.filter((u) => u.coachId === user.uid)
        setUsers(usersData)
      } else {
        setUsers([]) // Non-admin and non-coach users should not see any data
      }
    }

    fetchUsers()
  }, [user.uid, isAppAdmin, isCoach]) // Adding dependencies to the useEffect

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleRowClick = (user) => {
    localStorage.setItem('studentId', user.id)
    localStorage.setItem('studentName', user.name)
    if (user.vocalRange) {
      localStorage.setItem('studentVocalRange', user.vocalRange)
    }
    localStorage.setItem('studentEmail', user.email)
    navigate(`/student/${user.id}`)
  }

  const filteredUsers = users.filter((user) => {
    const fullName = user.name ? user.name.toLowerCase() : ''
    const userEmail = user.email ? user.email.toLowerCase() : ''
    return (
      fullName.includes(searchTerm.toLowerCase()) ||
      userEmail.includes(searchTerm.toLowerCase())
    )
  })

  return (
    <Box sx={{ p: 1, bgcolor: 'background.default', display: 'grid', gap: 1 }}>
      <Paper elevation={6}>
        <Box padding={1}>
          <Typography variant='h4' component='h2' marginLeft={0}>
            Students
          </Typography>
        </Box>
        <Box paddingLeft={2} paddingRight={2}>
          <TextField
            label='Search Students'
            variant='outlined'
            fullWidth
            margin='normal'
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Box>
        <Box paddingX={2} paddingBottom={2}>
          <TableContainer>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Email</StyledTableCell>
                  <StyledTableCell>Date</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((user) => (
                    <TableRow
                      key={user.id}
                      onClick={() => handleRowClick(user)}
                      style={{ cursor: 'pointer' }}>
                      <TableCell>{user.name}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>
                        {user.timestamp
                          ? new Date(
                              user.timestamp.seconds * 1000
                            ).toLocaleDateString()
                          : null}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component='div'
              count={filteredUsers.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Box>
      </Paper>
    </Box>
  )
}

export default StudentTable
