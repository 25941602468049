import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { styled, useTheme } from '@mui/material/styles'
import {
  Drawer as MuiDrawer,
  AppBar as MuiAppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  Box,
  Menu,
  MenuItem,
  useMediaQuery,
} from '@mui/material'
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  AccountCircle,
} from '@mui/icons-material'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import DrawerLinks from './DrawerLinks'

const drawerWidth = 240
const logo = 'logo.png' // SET THE CORRECT PATH FOR YOUR LOGO IMAGE

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: drawerWidth,
}))

const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    }),
  },
}))

function ToolbarDrawer() {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const auth = getAuth()
  const [user, setUser] = useState(null)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser)
    })
    const storedState = localStorage.getItem('drawerOpen')
    setOpen(storedState !== 'false')

    return () => unsubscribe()
  }, [])

  const toggleDrawer = () => {
    setOpen(!open)
    localStorage.setItem('drawerOpen', String(!open))
  }

  const [anchorEl, setAnchorEl] = useState(null)
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleProfileClick = () => {
    handleClose()
    navigate('/profile')
  }

  const handleLogoutClick = () => {
    handleClose()
    auth.signOut()
    navigate('/sign-in')
  }

  if (!user) {
    return null
  }

  return (
    <>
      <AppBar position='fixed'>
        <Toolbar>
          <IconButton
            edge='start'
            color='inherit'
            aria-label='open drawer'
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
            }}>
            <MenuIcon />
          </IconButton>
          <Box
            component='img'
            sx={{
              height: 42.81,
              width: 200,
              maxHeight: { xs: 233, md: 167 },
              maxWidth: { xs: 350, md: 250 },
            }}
            alt='Logo'
            src={`/images/${logo}`}
          />
          <Typography variant='h5' noWrap sx={{ flexGrow: 1 }}>
            {/* Insert your app name or breadcrumb here */}
          </Typography>

          {user && (
            <div>
              <IconButton
                size='large'
                aria-label='account of current user'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                onClick={handleMenu}
                color='inherit'>
                <Typography>{user.displayName}</Typography>
                &nbsp;
                <AccountCircle />
              </IconButton>
              <Menu
                id='menu-appbar'
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                <MenuItem onClick={handleProfileClick}>Account</MenuItem>
                <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        open={open}
        onClose={toggleDrawer}
        ModalProps={{
          keepMounted: true,
        }}>
        <Toolbar>
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List>
          <DrawerLinks />
          <Divider sx={{ my: 1 }} />
        </List>
      </Drawer>
    </>
  )
}

export default ToolbarDrawer
