import React, { useState, useEffect } from 'react'
import { getAuth, updateProfile } from 'firebase/auth'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Box,
  Button,
  Container,
  Grid,
  Link,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../firebase/config'
import { useGridStatePersistence } from '@mui/x-data-grid/internals';

export default function Profile() {
  const auth = getAuth()
  const navigate = useNavigate()
  const [changeDetail, setChangeDetail] = useState(false)
   const [formData, setFormData] = useState({
     firstName: '',
     lastName: '',
     userName: auth.currentUser.displayName,
     email: auth.currentUser.email,
     vocalRange: '',
     learnReason: '',
     genderIdentity: '',
     ageGroup: '',
     singingExperience: '',
     practiceTimeTarget: '',
     weeklyTarget: '',
   })

  useEffect(() => {
    async function fetchUserData() {
      if (!auth.currentUser?.uid) {
        console.log('AUTH_CURRENT_USER_UID_NIL', auth.currentUser)
        return
      }
      const userDoc = doc(db, 'users', auth.currentUser.uid)
      const userSnapshot = await getDoc(userDoc)
      if (userSnapshot.exists()) {
        setFormData((prevState) => ({
          ...prevState,
          firstName: userSnapshot.data().firstName || '',
          lastName: userSnapshot.data().lastName || '',
          vocalRange: userSnapshot.data().vocalRange || '',
          learnReason: userSnapshot.data().learnReason || '',
          genderIdentity: userSnapshot.data().genderIdentity || '',
          ageGroup: userSnapshot.data().ageGroup || '',
          userName: userSnapshot.data().displayName || '',
          singingExperience: userSnapshot.data().singingExperience || '',
          practiceTimeTarget: userSnapshot.data().practiceTimeTarget || '',
          weeklyTarget: userSnapshot.data().weeklyTarget || '',
        }))
      }
    }
    fetchUserData()
  }, [auth.currentUser])

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }))
  }

  const onVocalRangeChange = (event) => {
    setFormData((prevState) => ({
      ...prevState,
      vocalRange: event.target.value,
    }))
    localStorage.setItem('vocalRange', event.target.value)
  }

  const onLearnReasonChange = (event) => {
    // NEW HANDLER FOR LEARN REASON
    setFormData((prevState) => ({
      ...prevState,
      learnReason: event.target.value,
    }))
  }

  const onGenderIdentityChange = (event) => {
    setFormData((prevState) => ({
      ...prevState,
      genderIdentity: event.target.value,
    }))
  }

  const onAgeGroupChange = (event) => {
    setFormData((prevState) => ({
      ...prevState,
      ageGroup: event.target.value,
    }))
  }

  const onSingingExperienceChange = (event) => {
    setFormData((prevState) => ({
      ...prevState,
      singingExperience: event.target.value,
    }))
  }

  const onPracticeTimeTargetChange = (event) => {
    setFormData((prevState) => ({
      ...prevState,
      practiceTimeTarget: event.target.value,
    }))
  }

  const onWeeklyTargetChange = (event) => {
    setFormData((prevState) => ({
      ...prevState,
      weeklyTarget: event.target.value,
    }))
  }

  async function onSubmit() {
    try {
      if (auth.currentUser.displayName !== formData.userName) {
        await updateProfile(auth.currentUser, {
          displayName: formData.userName, // Updated variable reference
        })
      }
      //toast.success('Profile details updated')
    } catch (error) {
      toast.error('Could not update the profile details {updateProfile}.')
    }

    try {
      const ref = doc(db, 'users', auth.currentUser.uid)
      await updateDoc(ref, formData)
      toast.success('User preferences updated')
    } catch (error) {
      toast.error('Could not update user preferences {users}.')
    }
  }

  return (
    <React.Fragment>
      <Container maxWidth='md'>
        <Box my={4}>
          <Box mt={2}>
            <form>
              <TextField
                fullWidth
                margin='normal'
                id='firstName'
                label='First Name'
                type='text'
                value={formData.firstName}
                onChange={onChange}
                disabled={!changeDetail}
                variant='outlined'
              />
              <TextField
                fullWidth
                margin='normal'
                id='lastName'
                label='Last Name'
                type='text'
                value={formData.lastName}
                onChange={onChange}
                disabled={!changeDetail}
                variant='outlined'
              />
              <TextField
                fullWidth
                margin='normal'
                id='userName'
                label='Name'
                type='text'
                value={formData.userName}
                onChange={onChange}
                disabled={!changeDetail}
                variant='outlined'
              />{' '}
              <TextField
                fullWidth
                margin='normal'
                id='email'
                label='Email'
                type='email'
                value={formData.email}
                disabled
                variant='outlined'
              />
              <FormControl fullWidth margin='normal' variant='outlined'>
                <InputLabel id='vocalRangeLabel'>Vocal Range</InputLabel>
                <Select
                  labelId='vocalRangeLabel'
                  id='vocalRange'
                  value={formData.vocalRange}
                  onChange={onVocalRangeChange}
                  label='Vocal Range'
                  disabled={!changeDetail}>
                  <MenuItem value='LOW'>Low Voice</MenuItem>
                  <MenuItem value='HIGH'>High Voice</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth margin='normal' variant='outlined'>
                <InputLabel id='learnReasonLabel'>
                  What are your vocal training goals?
                </InputLabel>
                <Select
                  labelId='learnReasonLabel'
                  id='learnReason'
                  value={formData.learnReason}
                  onChange={onLearnReasonChange}
                  label='What are your vocal training goals?'
                  disabled={!changeDetail}>
                  <MenuItem value='PROFESSIONAL'>
                    I am a professional singer.
                  </MenuItem>
                  <MenuItem value='HOBBY'>I sing as a hobby.</MenuItem>
                  <MenuItem value='IMPROVE'>
                    I want to improve my vocal skills.
                  </MenuItem>
                  <MenuItem value='PERFORMANCE'>I perform at events.</MenuItem>
                  <MenuItem value='COMPETE'>
                    I compete in singing competitions.
                  </MenuItem>
                  <MenuItem value='CAREER'>
                    I am considering a career in music.
                  </MenuItem>
                  <MenuItem value='INSTRUCTION'>I am a music teacher.</MenuItem>
                  <MenuItem value='HEALTH'>
                    I sing for health benefits.
                  </MenuItem>
                  <MenuItem value='SPIRITUAL'>
                    I sing for spiritual or religious reasons.
                  </MenuItem>
                  <MenuItem value='OTHER'>Other reasons.</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth margin='normal' variant='outlined'>
                <InputLabel id='genderIdentityLabel'>
                  How do you identify?
                </InputLabel>
                <Select
                  labelId='genderIdentityLabel'
                  id='genderIdentity'
                  value={formData.genderIdentity}
                  onChange={onGenderIdentityChange}
                  label='How do you identify?'
                  disabled={!changeDetail}>
                  <MenuItem value='MALE'>Male</MenuItem>
                  <MenuItem value='FEMALE'>Female</MenuItem>
                  <MenuItem value='NONBINARY'>Non-binary</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth margin='normal' variant='outlined'>
                <InputLabel id='ageGroupLabel'>What is your age?</InputLabel>
                <Select
                  labelId='ageGroupLabel'
                  id='ageGroup'
                  value={formData.ageGroup}
                  onChange={onAgeGroupChange}
                  label='What is your age?'
                  disabled={!changeDetail}>
                  <MenuItem value='U13'>Under 13</MenuItem>
                  <MenuItem value='A14-17'>14-17</MenuItem>
                  <MenuItem value='A18-24'>18-24</MenuItem>
                  <MenuItem value='A25-34'>25-34</MenuItem>
                  <MenuItem value='A35-44'>35-44</MenuItem>
                  <MenuItem value='A45-54'>45-54</MenuItem>
                  <MenuItem value='A55-64'>55-64</MenuItem>
                  <MenuItem value='A65'>65 and over</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth margin='normal' variant='outlined'>
                <InputLabel id='singingExperienceLabel'>
                  Your singing experience?
                </InputLabel>
                <Select
                  labelId='singingExperienceLabel'
                  id='singingExperience'
                  value={formData.singingExperience}
                  onChange={onSingingExperienceChange}
                  label='Your singing experience?'
                  disabled={!changeDetail}>
                  <MenuItem value='NEVER'>I've never sung before</MenuItem>
                  <MenuItem value='BEGINNER'>Beginner</MenuItem>
                  <MenuItem value='INTERMEDIATE'>Intermediate</MenuItem>
                  <MenuItem value='ADVANCED'>Advanced</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth margin='normal' variant='outlined'>
                <InputLabel id='practiceTimeTargetLabel'>
                  Pick a practice time target
                </InputLabel>
                <Select
                  labelId='practiceTimeTargetLabel'
                  id='practiceTimeTarget'
                  value={formData.practiceTimeTarget}
                  onChange={onPracticeTimeTargetChange}
                  label='Pick a practice time target'
                  disabled={!changeDetail}>
                  <MenuItem value='5'>5 minutes per day</MenuItem>
                  <MenuItem value='10'>10 minutes per day</MenuItem>
                  <MenuItem value='15'>15 minutes per day</MenuItem>
                  <MenuItem value='20'>20 minutes per day</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth margin='normal' variant='outlined'>
                <InputLabel id='weeklyTargetLabel'>
                  Pick a weekly target
                </InputLabel>
                <Select
                  labelId='weeklyTargetLabel'
                  id='weeklyTarget'
                  value={formData.weeklyTarget}
                  onChange={onWeeklyTargetChange}
                  label='Pick a weekly target'
                  disabled={!changeDetail}>
                  <MenuItem value='1'>1 day a week</MenuItem>
                  <MenuItem value='2'>2 days a week</MenuItem>
                  <MenuItem value='3'>3 days a week</MenuItem>
                  <MenuItem value='4'>4 days a week</MenuItem>
                  <MenuItem value='5'>5 days a week</MenuItem>
                  <MenuItem value='6'>6 days a week</MenuItem>
                  <MenuItem value='7'>7 days a week</MenuItem>
                </Select>
              </FormControl>
              <Grid container spacing={2} justifyContent='space-between'>
                <Grid item>
                  <Button
                    color='primary'
                    onClick={() => {
                      changeDetail && onSubmit()
                      setChangeDetail((prevState) => !prevState)
                    }}>
                    {changeDetail ? 'Apply change' : 'Edit'}
                  </Button>
                </Grid>
              </Grid>
            </form>
            <Box my={2} textAlign='center'>
              <Link
                href='https://billing.stripe.com/p/login/dR62br5k6bHg9KU7ss'
                target='_blank'
                underline='none'>
                <Button variant='contained' color='primary'>
                  Manage Subscription
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  )
}
