import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig =  {
  apiKey: "AIzaSyDwbSKrSV9Pvg4vpeqWFAY145N7ChLKesM",
  authDomain: "vocalbrain-web.firebaseapp.com",
  projectId: "vocalbrain-web",
  storageBucket: "vocalbrain-web.appspot.com",
  messagingSenderId: "618550622183",
  appId: "1:618550622183:web:92d29de605a2de461aaf42",
  measurementId: "G-SPXEQCG5DN"
}

// Initialize Firebase
initializeApp(firebaseConfig)
const db = getFirestore()

export { db, firebaseConfig }
