export const computeTotalActivities = (activities) => {
  const oneWeekAgo = new Date()
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7) // Set the date to one week ago
  // Filter activities to include only those within the last week
  const recentActivities = activities.filter((activity) => {
    // Assuming activity.timestamp is an object with { seconds, nanoseconds }
    const activityDate = new Date(
      activity.timestamp.seconds * 1000 +
        activity.timestamp.nanoseconds / 1000000
    )
    return activityDate >= oneWeekAgo
  })
  return recentActivities.length // Return the count of recent activities
}

export const computeCurrentStreak = (activities) => {
  if (activities.length === 0) return 0

  // Sort activities by timestamp
  activities.sort((a, b) => {
    return (
      new Date(a.timestamp.seconds * 1000 + a.timestamp.nanoseconds / 1000000) -
      new Date(b.timestamp.seconds * 1000 + b.timestamp.nanoseconds / 1000000)
    )
  })

  let currentStreak = 1
  let lastActivityDate = new Date(
    activities[0].timestamp.seconds * 1000 +
      activities[0].timestamp.nanoseconds / 1000000
  )

  for (let i = 1; i < activities.length; i++) {
    const currentDate = new Date(
      activities[i].timestamp.seconds * 1000 +
        activities[i].timestamp.nanoseconds / 1000000
    )

    // Calculate difference in days between the current and last activity
    const diffInTime = currentDate.getTime() - lastActivityDate.getTime()
    const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24))

    if (diffInDays === 1) {
      currentStreak += 1
    } else if (diffInDays > 1) {
      break
    }

    lastActivityDate = currentDate
  }

  return currentStreak
}

export const computeLongestStreak = (activities) => {
  if (activities.length === 0) return 0

  // Sort activities by timestamp
  activities.sort((a, b) => {
    return (
      new Date(a.timestamp.seconds * 1000 + a.timestamp.nanoseconds / 1000000) -
      new Date(b.timestamp.seconds * 1000 + b.timestamp.nanoseconds / 1000000)
    )
  })

  let longestStreak = 1
  let currentStreak = 1
  let lastActivityDate = new Date(
    activities[0].timestamp.seconds * 1000 +
      activities[0].timestamp.nanoseconds / 1000000
  )

  for (let i = 1; i < activities.length; i++) {
    const currentDate = new Date(
      activities[i].timestamp.seconds * 1000 +
        activities[i].timestamp.nanoseconds / 1000000
    )

    // Calculate difference in days between the current and last activity
    const diffInTime = currentDate.getTime() - lastActivityDate.getTime()
    const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24))

    if (diffInDays === 1) {
      currentStreak += 1
      longestStreak = Math.max(longestStreak, currentStreak)
    } else if (diffInDays > 1) {
      currentStreak = 1
    }

    lastActivityDate = currentDate
  }

  return longestStreak
}
