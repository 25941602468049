import React from 'react'
import {
  Box,
  Button,
  Typography,
  Select,
  MenuItem,
  Slider,
  Card,
  CardContent,
} from '@mui/material'

function WorkoutModal({
  focus,
  setFocus,
  time,
  setTime,
  intensity,
  setIntensity,
  onGenerateWorkout,
}) {

  const allowedLabels = ['Chest', 'Breath', 'Head', 'Mix'] // Define the allowed labels

  return (
    <Card sx={{ maxWidth: 400, mx: 'auto', my: 3 }}>
      <CardContent>
        <Typography variant='h6' textAlign='center'>
          Customize Your Workout
        </Typography>

        <Box sx={{ mt: 2 }}>
          <Typography variant='subtitle1'>Focus</Typography>
          <Typography variant='caption'>
            Choose the main focus of your workout.
          </Typography>
          <Select
            value={focus}
            onChange={(e) => setFocus(e.target.value)}
            displayEmpty
            fullWidth
            sx={{ mt: 1 }}>
            {allowedLabels.map((label) => (
              <MenuItem key={label} value={label}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Typography variant='subtitle1'>Time (in minutes)</Typography>
          <Typography variant='caption'>
            Select the total duration of your workout.
          </Typography>
          <Select
            value={time}
            onChange={(e) => setTime(e.target.value)}
            displayEmpty
            fullWidth
            sx={{ mt: 1 }}>
            {[10, 15, 20, 30].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </Box>

        {false && ( // MRR: NOT SHOWING THIS RIGHT NOW
          <Box sx={{ mt: 2 }}>
            <Typography variant='subtitle1'>Intensity</Typography>
            <Typography variant='caption'>
              Adjust the intensity. Higher values mean more focus on the
              selected area.
            </Typography>
            <Slider
              value={intensity}
              min={1}
              max={10}
              step={1}
              onChange={(e, newValue) => setIntensity(newValue)}
              sx={{ mt: 2 }}
            />
          </Box>
        )}

        <Button
          variant='contained'
          onClick={() => onGenerateWorkout(focus, time, intensity)}
          size='large'
          sx={{ mt: 2, width: '100%' }}>
          Generate Workout
        </Button>
      </CardContent>
    </Card>
  )
}

export default WorkoutModal
