import React, { useState, useEffect } from 'react'
import { db } from '../../firebase/config'
import { collection, addDoc, updateDoc, doc } from 'firebase/firestore'
import { Modal, Box, Typography, TextField, Button } from '@mui/material'
import { toast } from 'react-toastify'

function CustomVideoModal({
  open,
  onClose,
  lessonId,
  onVideoAdded,
  maxOrder,
  editableVideo,
}) {
  const [name, setName] = useState('')
  const [url, setUrl] = useState('')

  useEffect(() => {
    if (editableVideo) {
      setName(editableVideo.name || '')
      setUrl(editableVideo.url || '')
    }
  }, [editableVideo])

  const handleAddOrUpdateVideo = async () => {
    if (!name.trim() || !url.trim()) {
      toast.error('Please provide both a name and a URL.')
      return
    }

    const videoData = {
      createdAt: new Date(),
      name,
      url,
      order: editableVideo ? editableVideo.order : maxOrder + 1,
      type: 'video', // Type is set to differentiate video content
    }

    try {
      const videoRef = editableVideo
        ? doc(db, `lessons/${lessonId}/slices`, editableVideo.id)
        : collection(db, `lessons/${lessonId}/slices`)
      const action = editableVideo
        ? updateDoc(videoRef, videoData)
        : addDoc(videoRef, videoData)
      await action
      onClose(true)
      onVideoAdded()
      toast.success(
        editableVideo
          ? 'Video updated successfully!'
          : 'Video added successfully!'
      )
      // Reset name and URL after successfully adding or updating a video
      if (!editableVideo) {
        setName('')
        setUrl('')
      }
    } catch (error) {
      toast.error('Error updating video in lesson.')
    }
  }

  return (
    <Modal
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby='modal-title'>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 'auto',
          maxWidth: '90vw',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          outline: 'none',
        }}>
        <Typography id='modal-title' variant='h6' sx={{ mb: 2 }}>
          {editableVideo ? 'Edit Video in Workout' : 'Add Video to Workout'}
        </Typography>
        <TextField
          label='Name'
          value={name}
          onChange={(e) => setName(e.target.value)}
          margin='normal'
          fullWidth
        />
        <TextField
          label='URL'
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          margin='normal'
          fullWidth
        />
        <Typography variant='body2' color='textSecondary' sx={{ mt: 1 }}>
          Note: The video URL must be embeddable (e.g., a YouTube embed URL).
        </Typography>
        <Button
          variant='contained'
          onClick={handleAddOrUpdateVideo}
          sx={{ mt: 2 }}>
          {editableVideo ? 'Update Video' : 'Add Video'}
        </Button>
      </Box>
    </Modal>
  )
}

export default CustomVideoModal
