// helper!

export function getExercisesByTag(exerciseTag, exercises, max) {
  let retExercises = []

  for (let exercise of exercises) {
    console.log('Current exercise tags:', exercise.TAGS)
    

    if (
      exercise.TAGS?.some((tagItem) => {
        const isTagItem = tagItem.includes(exerciseTag)
        console.log(
          'Checking tag item:',
          tagItem,
          'Contains tag?',
          isTagItem
        )
        return isTagItem
      })
    ) {
      retExercises.push(exercise)
      console.log('EXERCISE_CODE_MATCH', exercise)
    }
  }

  if (max && retExercises.length > max) {
    retExercises = shuffleArray(retExercises).slice(0, max)
  }

  return retExercises
}

// Utility function to shuffle an array
function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]] // Swap elements
  }
  return array
}

export function getExercisesByLabel(exerciseLabel, exercises, max) {
  let retExercises = []

  for (let exercise of exercises) {
    console.log('Current Exercise labels:', exercise.labels)
    
    if (
      exercise.labels?.some((labelItem) => {
        const isLabelItem = labelItem.includes(exerciseLabel)
        console.log(
          'Checking label item:',
          labelItem,
          'Contains label?',
          isLabelItem
        )
        
        return isLabelItem
      })
    ) {
      retExercises.push(exercise)
      console.log('EXERCISES_LABEL_MATCH', exercise)
    }
  }

  if (max && retExercises.length > max) {
    retExercises = shuffleArray(retExercises).slice(0, max)
  }

  return retExercises
}


export function getWarmupExerciseCount(workoutDuration) {
  if (workoutDuration <= 15) {
    return 1 // 1 warm-up for 15 minutes or less
  } else {
    // Calculate the number of warm-ups for durations over 15 minutes, up to 30 minutes
    const warmups = Math.ceil((workoutDuration - 15) / 5)
    return Math.min(warmups + 1, 4) // Ensures that the count does not exceed 4
  }
}


