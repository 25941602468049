import React from 'react'
import { Box, Typography, Card, CardContent } from '@mui/material'
import MusicNoteIcon from '@mui/icons-material/MusicNote'
import { useTheme } from '@mui/material/styles'
import { quotes } from './quotesArray.js' // <-- Ensure the path is correct

function Quote() {
  const theme = useTheme()
  const getRandomQuote = () => quotes[Math.floor(Math.random() * quotes.length)]

  const quoteBoxStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    mx: 1,
  }

  return (
    <Card
      sx={{
        borderRadius: '20px',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'background.paper',
        color: 'text.primary',
        position: 'relative',
        overflow: 'hidden',
        maxWidth: 345,
        margin: 'auto',
        height: '100%', // Ensure the card takes full height
        '&:hover': { boxShadow: '0px 8px 40px rgba(0, 0, 0, 0.2)' },
        '@media (max-width: 600px)': { maxWidth: '100%' },
      }}>
      <CardContent style={{ padding: '16px', textAlign: 'center' }}>
        <Box sx={quoteBoxStyle}>
          <MusicNoteIcon color='action' sx={{ mb: 1 }} />
          <Typography variant='body1' sx={{ fontStyle: 'italic' }}>
            {getRandomQuote()}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )
}

export default Quote
