// VideoPage.js
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {
  doc,
  getDoc,
  collection,
  query,
  onSnapshot,
  orderBy,
  deleteDoc,
} from 'firebase/firestore'
import { db } from '../../firebase/config'
import VideoFrame from './VideoFrame'
import VideoInfo from './VideoInfo'
import CommentForm from './CommentForm'
import Comment from './Comment'
import {
  Container,
  Typography,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material'

function convertLoomShareToEmbedUrl(shareUrl) {
  if (!shareUrl) {
    console.error('shareUrl is undefined');
    return '';
  }

  const videoId = shareUrl.split('/').pop();
  return `https://www.loom.com/embed/${videoId}`;
}


// ... other code ...

const VideoPage = () => {
  const [videoData, setVideoData] = useState(null)
  const [error, setError] = useState(false)
  const { documentId } = useParams()
  const [comments, setComments] = useState([])
  const [open, setOpen] = useState(false)

  const navigate = useNavigate();

  useEffect(() => {
    const fetchComments = () => {
      const commentsRef = collection(db, 'videos', documentId, 'comments')
      const q = query(commentsRef, orderBy('date', 'desc'))

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const commentsData = []
        querySnapshot.forEach((doc) => {
          const commentData = doc.data()
          commentData.id = doc.id
          commentsData.push(commentData)
        })

        setComments(commentsData)
      })

      return unsubscribe
    }

    if (documentId) {
      return fetchComments()
    }
  }, [documentId])

  useEffect(() => {
    const fetchData = async () => {
      const docRef = doc(db, 'videos', documentId)
      const docSnapshot = await getDoc(docRef)

      if (docSnapshot.exists()) {
        setVideoData(docSnapshot.data())
      } else {
        console.log('No such document!')
        setError(true)
      }
    }

    fetchData()
  }, [documentId])

  if (error) {
    return (
      <Container>
        <Typography variant='h4' gutterBottom>
          Video not found
        </Typography>
        <Typography variant='body1'>
          Sorry, the video you are looking for could not be found.
        </Typography>
      </Container>
    )
  }

  if (!videoData) {
    return <div>Loading...</div>
  }

  const { date, uploader, url, videoTitle, isActive } = videoData
  const embedUrl = convertLoomShareToEmbedUrl(url)
  const formattedDate = date ? date.toDate() : null // Convert Firestore Timestamp to JavaScript Date object, if available

  

  

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const deleteVideo = async () => {
    if (documentId) {
      const docRef = doc(db, 'videos', documentId)
      await deleteDoc(docRef)
      handleClose()
      navigate(-1) // Navigate to the previous page
    }
  }

  // ... other code ...

  return (
    <Container>
      <Typography variant='h4' gutterBottom>
        {videoTitle}
      </Typography>
      <VideoFrame url={embedUrl} />
      <Box mt={3}>
        <VideoInfo
          date={formattedDate}
          uploader={uploader}
          videoTitle={videoTitle}
          isActive={isActive}
        />
      </Box>
      <Box mt={3} mb={3}>
        <Button variant='contained' color='error' onClick={handleClickOpen}>
          Delete Video
        </Button>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Delete Video</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this video? This action cannot be
              undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button variant='contained' color='error' onClick={deleteVideo}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <CommentForm videoId={documentId} />
      <Box mt={3}>
        {comments.map((comment) => (
          <Comment
            key={comment.id}
            commentText={comment.commentText}
            //commenterName={comment.commenterName}
            commentName={'<INSTRUCTOR_NAME>'}
            date={comment.date.toDate()}
          />
        ))}
      </Box>
    </Container>
  )
}

export default VideoPage
