import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

function GuideAccordion(props) {
  const { expanded, handleChange } = props

  return (
    <>
      <Accordion
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1bh-content'
          id='panel1bh-header'>
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
            Course Overview
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            Discover the essentials to get started with VocalBrain.
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The VocalBrain Course is your starting point for vocal mastery, with
            guidance on vocal health, breath support, and techniques for all
            levels. Access from the main menu and proceed through the chapters
            at your own pace.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel2'}
        onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel2bh-content'
          id='panel2bh-header'>
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Trainers</Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            Interactive tools for practical vocal training.
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Utilize VocalBrain's trainers for targeted pitch practice. Choose
            exercises from the dropdown, follow the visual cues, and match
            pitches to develop your ear and control.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel3'}
        onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel3bh-content'
          id='panel3bh-header'>
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
            Custom Workouts
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            Tailored exercises for a personalized training experience.
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Engage in workouts created by VocalBrain to enhance your singing
            abilities, focusing on areas like pitch accuracy, breath control,
            and articulation. Progress through levels to unlock advanced vocal
            exercises.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel4'}
        onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel4bh-content'
          id='panel4bh-header'>
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
            Exercises Library
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            A collection of exercises for focused skill improvement.
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Access a curated list of vocal exercises to work on specific skills,
            with the flexibility to choose keys and set repetitions Each
            exercise is designed to challenge and expand your vocal
            capabilities, whether it's through warming up, skill training, or
            simply having fun with your voice.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  )
}

function ControlledAccordions() {
  const [expanded, setExpanded] = React.useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <Box paddingLeft={2} paddingRight={2} paddingBottom={1}>
      <Paper elevation={6}>
        <Box padding={2}>
          <Typography variant='h5' gutterBottom>
            VocalBrain Modules
          </Typography>
          <Typography>
            Explore the essential modules of VocalBrain: Courses, Workouts, and
            Exercises. Designed to provide comprehensive and interactive vocal
            training, each module supports your journey to vocal excellence.
          </Typography>
          <Typography variant='body2' sx={{ mt: 1 }}>
            Select a section to learn more and start enhancing your vocal
            skills.
          </Typography>
          <GuideAccordion expanded={expanded} handleChange={handleChange} />
        </Box>
      </Paper>
    </Box>
  )
}

export default function Guide() {
  return <ControlledAccordions />
}
