import React, { useEffect, useState } from 'react'
import Spinner from '../../Spinner'
// import { getNoteFromMidi } from '../../components/util/MusicDataMaps'
import AudioRecorder from '../../components/recorders/AudioRecorder'
import Iframe from 'react-iframe'

const baseUrl = `/_trainer` // @MRR -- TODO: Get from config.

function buildUrl(exerciseType, startNote, playerSpeed, playToKey) {
  return `${baseUrl}/?et=${exerciseType}&sn=${startNote}&player_speed=${playerSpeed}&ptk=${playToKey}`
}

const TrainerFrame = (props) => {
  const { exerciseType, startNote, playerSpeed, playToKey } = props
  const url = buildUrl(exerciseType, startNote, playerSpeed, playToKey)

  console.log(url)

  return (
    <Iframe
      url={url}
      width='100%'
      height='100%'
      id='VBP_IFRAME_TRAINER'
      className='vbpFrame'
      display='block'
      frameBorder='0'
      allow='microphone; https://vbp.vocalbrain.com'
      style={{ flex: 1 }}
    />
  )
}

const TrainerCard = (props) => {
  const [exerciseType, setExerciseType] = useState('13531')
  const [startNote, setStartNote] = useState('49')

  useEffect(() => {
    setExerciseType(props.exerciseType)
  }, [props.exerciseType])

  useEffect(() => {
    const lsvr = localStorage.getItem('vocalRange')
    if (lsvr) {
      const vocalRange = lsvr.toUpperCase()
      if (vocalRange === 'LOW') {
        setStartNote('49')
      }
      if (vocalRange === 'HIGH') {
        setStartNote('57')
      }
    }
  }, [])

  const renderView = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '80vh',
          margin: 0,
          padding: 0,
        }}>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <TrainerFrame exerciseType={exerciseType} startNote={startNote} />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '3px',
            margin: 0,
          }}>
          <AudioRecorder />
        </div>
      </div>
    )
  }

  if (props.isPending) {
    return <Spinner />
  }

  return renderView()
}

function Trainers() {
  return (
    <TrainerCard exerciseType='13531' playerSpeed='normal' isPending={false} />
  )
}

export default Trainers
