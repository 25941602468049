import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from 'firebase/auth'
import { toast } from 'react-toastify'
import googleIcon from '../src/assets/svg/googleIcon.svg' // Ensure this path is correct
import logo from '../src/assets/VocalBrain_Logo.png' // Ensure this path is correct
import './OAuth.css' // Ensure this path is correct

function OAuthButton() {
  const navigate = useNavigate()
  const onGoogleClick = async () => {
    try {
      const auth = getAuth()
      const provider = new GoogleAuthProvider()
      const result = await signInWithPopup(auth, provider)
      const user = result.user

      if (!user) {
        throw new Error('Authentication failed')
      }
      navigate('/')
    } catch (error) {
      toast.error('Could not authorize with Google')
    }
  }

  return (
    <button className='google-btn' onClick={onGoogleClick}>
      <img src={googleIcon} alt='Sign in with Google' className='google-icon' />
      Sign in with Google
    </button>
  )
}

function SignIn() {
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  })

  const handleChange = (event) => {
    const { name, value } = event.target
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const { email, password } = formData

    try {
      const auth = getAuth()
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      )
      if (userCredential.user) {
        navigate('/')
      }
    } catch (error) {
      toast.error('Bad User Credentials')
    }
  }

  return (
    <div className='signup-container'>
      <div className='brand-logo'>
        <img src={logo} alt='VocalBrain Logo' className='logo' />
      </div>

      <OAuthButton />

      <div className='or-separator'>OR</div>

      <form className='signup-form' onSubmit={handleSubmit}>
        <input
          type='email'
          className='form-input'
          name='email'
          placeholder='Email Address'
          required
          value={formData.email}
          onChange={handleChange}
          autoFocus
        />
        <input
          type='password'
          className='form-input'
          name='password'
          placeholder='Password'
          required
          value={formData.password}
          onChange={handleChange}
        />
        <button type='submit' className='submit-btn'>
          Sign In
        </button>
      </form>

      <div className='link-container'>
        <Link to='/forgot-password' className='simple-link'>
          Forgot password?
        </Link>
        <Link to='/sign-up' className='simple-link'>
          Don't have an account? Sign Up
        </Link>
      </div>
    </div>
  )
}

export default SignIn
