import { createTheme } from '@mui/material/styles'
import { red } from '@mui/material/colors'

// // A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      // main: '#556cd6',
      main: '#4253FD',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
  },
})

export default theme

// import { createTheme } from '@mui/material/styles'

// const theme = createTheme({
//   components: {
//     MuiButton: {
//       styleOverrides: {
//         root: {
//           fontSize: '0.875rem',
//           fontWeight: 600,
//           borderRadius: 8.5,
//           textTransform: 'none',
//           '&.MuiButton-contained': {
//             backgroundColor: '#009be5',
//             '&:hover': {
//               backgroundColor: '#006db3',
//             },
//           },
//           '&.MuiButton-outlined': {
//             color: '#fff',
//             borderColor: 'rgba(255, 255, 255, 0.7)',
//             '&:hover': {
//               backgroundColor: 'rgba(0, 0, 0, 0.04)',
//             },
//           },
//           '&.Mui-disabled': {
//             backgroundColor: 'rgba(0, 0, 0, 0.12)',
//           },
//         },
//       },
//     },
//     MuiSvgIcon: {
//       styleOverrides: {
//         root: {
//           fontSize: '1.7rem',
//         },
//       },
//     },
//   },
//   // palette: {
//   //   white: {
//   //     main: '#fff',
//   //   },
//   // },

//   palette: {
//     primary: {
//       main: '#556cd6',
//     },
//     secondary: {
//       main: '#19857b',
//     },
//     error: {
//       main: red.A400,
//     },
//   },
//   typography: {
//     h1: {
//       fontSize: '1.6rem',
//       fontWeight: 600,
//       color: '#fff',
//       letterSpacing: '0.5px',
//       textTransform: 'capitalize',
//     },
//   },
// })

// export default theme
