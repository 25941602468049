import React, { useEffect, useState, useContext } from 'react'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { doc, getDoc, collection, getDocs } from 'firebase/firestore'
import { db } from './firebase/config'

export const UserContext = React.createContext()

export const UserProvider = (props) => {
  const [session, setSession] = useState({
    user: null,
    userData: null,
    isAppAdmin: false,
    loading: true,
    isPremium: false,
  })

  async function checkSubscriptions(uid, userCreationTime) {
    if (!uid) return false

    try {
      const subscriptionsRef = collection(db, 'users', uid, 'subscriptions')
      const querySnapshot = await getDocs(subscriptionsRef)
      let active = false

      querySnapshot.forEach((docSnapshot) => {
        console.log(
          `Subscription ID: ${docSnapshot.id}, Data: `,
          docSnapshot.data()
        )
        if (isSubscriptionActive(docSnapshot.data())) {
          active = true
        }
      })

      const now = new Date()
      const creationDate = new Date(userCreationTime * 1000) // Convert to milliseconds
      const thirtyDaysAgo = new Date(now.setDate(now.getDate() - 30))

      // Check if user was created within the last 30 days
      if (creationDate >= thirtyDaysAgo) {
        active = true
      }

      return active
    } catch (error) {
      console.error('Error fetching subscriptions:', error)
      return false
    }
  }

  function isSubscriptionActive(subscription) {
    if (!subscription || !subscription.current_period_end) {
      console.error('Invalid subscription data:', subscription)
      return false
    }

    const now = new Date()
    const currentPeriodEnd = subscription.current_period_end.toDate()

    console.log('Now:', now, 'Current Period End:', currentPeriodEnd)
    return now < currentPeriodEnd
  }

  useEffect(() => {
    const unsubscribe = getAuth().onAuthStateChanged(async (user) => {
      console.info('USER_PROVIDER_USER', user)
      let isAppAdmin = false
      let userData = null
      let isPremium = false
      let isCoach = false

      if (user) {
        const docRef = doc(db, 'users', user.uid)
        const docSnap = await getDoc(docRef)

        if (docSnap.exists()) {
          userData = docSnap.data()
          console.log('USER', user)
          console.log('USER_DATA:', userData)

          // Get the user's creation time
          const userCreationTime = user.metadata.creationTime
            ? new Date(user.metadata.creationTime).getTime() / 1000
            : null

          isPremium = await checkSubscriptions(user.uid, userCreationTime)

          // Will overide as needed.
          if (userData.isPremium) {
            isPremium = true
          }

          if (userData.isAdmin) {
            isAppAdmin = true
          }

          if (userData.isCoach) {
            isCoach = true
          }

          if (userData.vocalRange) {
            const ud = userData.vocalRange.toUpperCase()

            if (!ud) {
              console.warn('WRN_NO_VOC_RNG_SET', 'SYSTEM WILL DEFAULT TO LOW')
            }

            if (ud && ud !== 'HIGH' && ud !== 'LOW') {
              console.log('INVALID VOCAL RANGE IN USER DATA')
              localStorage.removeItem('vocalRange')
            } else {
              localStorage.setItem('vocalRange', ud)
            }
          }
        } else {
          console.error('ERR_NO_USER_DOC')
        }
      }
      setSession({
        user,
        userData,
        isAppAdmin,
        loading: false,
        isPremium,
        isCoach: isCoach,
      })
    })

    return () => unsubscribe()
  }, [])

  return (
    <UserContext.Provider value={session}>
      {!session.loading && props.children}
    </UserContext.Provider>
  )
}

export const useSession = () => {
  const session = useContext(UserContext)
  return session
}

export default UserProvider
