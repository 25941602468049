// export const quotes = [
//   "Songwriting is hell on Earth. If it isn't, then you're doing it wrong. — Jimmy Webb",
//   "A songwriter's supreme challenge is being complex and simple at the same time. — Jimmy Webb",
//   'Arranging is the way I put my songs in motion. — Jimmy Webb',
//   "I like to think of my songs as 'Letters' that I have sent to my friends and to my enemies. — Jimmy Webb",
//   'You have to love the characters that you write about. You have to be them to an extent. — Jimmy Webb',
//   'Songs are like tattoos. — Joni Mitchell',
//   'All good music resembles something. Good music stirs by its mysterious resemblance to the objects and feelings which motivated it. — Jean Cocteau',
//   "Songwriting is a kind of meditation for me. It's very much a Zen process. — Sheryl Crow",
//   'The best songs often take two disparate ideas and make them fit together. — Ed Sheeran',
//   'Songwriting is too mysterious and uncontrolled a process for me to direct it towards any one thing. — James Taylor',
//   'Music is what feelings sound like. — Duke Ellington',
//   'The craft of songwriting is to make the complex understandable and accessible. — Shelly Peiken',
//   'Music should strike fire from the heart of man, and bring tears from the eyes of a woman. — Ludwig van Beethoven',
//   "I think the Global Songwriters Connection isn't just about the business of music. It's about the heart of music. — Anonymous",
// ]

export const quotes = [
  'Music is what feelings sound like. — Duke Ellington',
  "Music expresses that which cannot be put into words and that which cannot remain silent. — Victor Hugo",
  "Music is the universal language of mankind. — Henry Wadsworth Longfellow",
  "Life seems to go on without effort when I am filled with music. — George Eliot",
  "Music is the shorthand of emotion. — Leo Tolstoy",
  "Music can change the world because it can change people. — Bono",
  "Music is the moonlight in the gloomy night of life. — Jean Paul",
  "Where words fail, music speaks. — Hans Christian Andersen",
  "Music acts like a magic key, to which the most tightly closed heart opens. — Maria von Trapp",
  "Music is an outburst of the soul. — Frederick Delius",
  "A painter paints pictures on canvas. But musicians paint their pictures on silence. — Leopold Stokowski",
  "Music is the literature of the heart; it commences where speech ends. — Alphonse de Lamartine",
  "Music is the emotional life of most people. — Leonard Cohen",
  "Music washes away from the soul the dust of everyday life. — Berthold Auerbach",
  "Music is the divine way to tell beautiful, poetic things to the heart. — Pablo Casals",
  "Music is healing. Music holds things together. — Prince",
  "If music be the food of love, play on. — William Shakespeare",
  "I've found that no matter what life throws at me, music softens the blow. — Bryce Anderson",
  "Music and rhythm find their way into the secret places of the soul. — Plato",
  "Music can heal the wounds which medicine cannot touch. — Debasish Mridha",
  "The only thing better than singing is more singing. — Ella Fitzgerald",
  "I don't sing because I'm happy; I'm happy because I sing. — William James",
  "Singing provides a true sense of lightheartedness. If I sing when I am alone, I feel wonderful. It's freedom. — Andrea Bocelli",
  "The human voice is the most beautiful instrument of all, but it is the most difficult to play. — Richard Strauss",
  "Singing is a way of escaping. It's another world. I'm no longer on earth. — Edith Piaf",
  "Singing is my passion, my first love and the secret of my energy. Music to me is like finding my inner self, my soul. — Kailash Kher",
  "Singing acts as a therapy and is a way of relieving tension. — Charlotte Church",
  "There's no half-singing in the shower, you're either a rock star or an opera diva. — Josh Groban",
  "A great singer is like a great athlete. You don’t become one unless you are constantly working, constantly refining, constantly improving. — Tony Bennett",
  "Your voice is a muscle and your talent is like a little ember. You’ve got to keep the flame alive. — Katy Perry"
];
