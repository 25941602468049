import React, { useState, useEffect } from 'react'
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Grid,
  Button,
  Checkbox,
  FormControlLabel,
  Container,
  Box,
  CircularProgress,
} from '@mui/material'
import { getApp } from '@firebase/app'
import {
  getStripePayments,
  createCheckoutSession,
} from '@stripe/firestore-stripe-payments'

// Plan information - Ensure the plan details are up to date
const plans = [
  {
    name: 'Singer',
    price: '9.95',
    available: true,
    stripePriceId: 'price_1PDVyFEnu657edsg53O9vWSU',
    features: { dailyWorkouts: true, basicTools: true, advancedTools: false },
    promotion: 'Unlock your full vocal potential with our premium plan!',
  },
]

function PlanCard({ plan }) {
  const [payments, setPayments] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const app = getApp()
    const stripePayments = getStripePayments(app, {
      productsCollection: 'products',
      customersCollection: 'users',
    })
    setPayments(stripePayments)
  }, [])

  const handleSubscribe = async () => {
    try {
      setLoading(true)
      const session = await createCheckoutSession(payments, {
        price: plan.stripePriceId, // Ensure this Stripe Price ID is correct
        success_url: 'https://app.vocalbrain.com/intro/step-1', // URL to redirect upon successful subscription
        cancel_url: 'https://app.vocalbrain.com', // URL to redirect upon cancellation
      })
      window.location.assign(session.url)
    } catch (error) {
      console.error('Error creating checkout session:', error)
      setLoading(false)
    }
  }

  const SubscribeButton = plan.available ? (
    <Button
      variant='contained'
      color='primary'
      onClick={handleSubscribe}
      disabled={loading}>
      {loading ? <CircularProgress size={24} /> : 'Subscribe'}
    </Button>
  ) : (
    <Button variant='contained' disabled>
      Coming Soon!
    </Button>
  )

  return (
    <Card variant='outlined'>
      <CardContent>
        <Typography variant='h5' component='div'>
          {plan.name}
        </Typography>
        <Typography variant='h6' color='text.secondary'>
          ${plan.price}/month
        </Typography>
        <Typography variant='body2' color='text.primary'>
          {plan.promotion}
        </Typography>
        <FormControlLabel
          control={<Checkbox checked={plan.features.dailyWorkouts} />}
          label='Daily Vocal Workouts'
        />
        <FormControlLabel
          control={<Checkbox checked={plan.features.basicTools} />}
          label='Basic Trainer Tools'
        />
        <FormControlLabel
          control={<Checkbox checked={plan.features.advancedTools} />}
          label='Advanced Trainer Tools'
        />
      </CardContent>
      <CardActions>{SubscribeButton}</CardActions>
    </Card>
  )
}

function PlanSelection() {
  return (
    <Container>
      <Box style={{ marginTop: '20px' }}>
        <Grid container spacing={2}>
          {plans.map((plan, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <PlanCard plan={plan} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  )
}

export default PlanSelection
