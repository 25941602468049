export const cardContentMap = new Map([
  [
    'guide',
    {
      title: 'Guidance',
      subtitle: 'Expert Tips & Tricks',
      description:
        'Dive into a wealth of knowledge to enhance your vocal skills.',
      linkText: 'Explore Guidance',
      linkTo: '/guide',
    },
  ],
  [
    'course',
    {
      title: 'Vocal Tutorials',
      subtitle: 'Our Methods',
      description:
        'Engage with comprehensive courses to systematically improve your singing.',
      linkText: 'View Tutorials',
      linkTo: '/tutorial/intro',
    },
  ],
  [
    'songwriting',
    {
      title: 'Song Writing',
      subtitle: 'Lyrics & Composition',
      description:
        'Master the art of songwriting with courses designed to boost your lyrical creativity and composition skills.',
      linkText: 'Learn Songwriting',
      linkTo: '/tutorial/gsc',
    },
  ],
  [
    'trainers',
    {
      title: 'Voice Training',
      subtitle: 'Real-time Feedback',
      description:
        'Utilize cutting-edge tools to get instant feedback on your pitch and tone.',
      linkText: 'Start Training',
      linkTo: '/trainers',
    },
  ],
  [
    'workouts',
    {
      title: 'Vocal Workouts',
      subtitle: 'Practice Makes Perfect',
      description:
        'Tailored exercises to strengthen your voice and expand your range.',
      linkText: 'Begin Workout',
      linkTo: '/workouts',
    },
  ],
  [
    'exercises',
    {
      title: 'Singing Exercises',
      subtitle: 'Skill Building',
      description:
        'Isolate and develop specific vocal skills with targeted exercises.',
      linkText: 'View Exercises',
      linkTo: '/slices',
    },
  ],
])
