import React, { useState, useEffect } from 'react'
import { getAuth } from 'firebase/auth'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Button,
  Container,
  Grid,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../firebase/config' // VERY IMPORTANT: Confirm correct path to firebase config

export default function Step5() {
  const auth = getAuth()
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    practiceTimeTarget: '',
  })

  useEffect(() => {
    async function fetchUserData() {
      if (!auth.currentUser?.uid) {
        console.log('AUTH_CURRENT_USER_UID_NIL', auth.currentUser)
        return
      }
      const userDoc = doc(db, 'users', auth.currentUser.uid)
      const userSnapshot = await getDoc(userDoc)
      if (userSnapshot.exists()) {
        setFormData((prevState) => ({
          ...prevState,
          practiceTimeTarget: userSnapshot.data().practiceTimeTarget || '',
        }))
      }
    }
    fetchUserData()
  }, [auth.currentUser])

  const onPracticeTimeTargetChange = (event) => {
    setFormData({
      practiceTimeTarget: event.target.value,
    })
  }

  async function onSubmit() {
    try {
      const ref = doc(db, 'users', auth.currentUser.uid)
      await updateDoc(ref, {
        practiceTimeTarget: formData.practiceTimeTarget,
      })
      //toast.success('Practice time target updated')
      navigate('/intro/step-6') // VERY IMPORTANT: Adjust navigation path according to your routing configuration
    } catch (error) {
      toast.error('Could not update practice time target {users}.')
    }
  }

  const handleBack = () => {
    navigate(-1) // Navigate to the previous page
  }

  return (
    <React.Fragment>
      <Container maxWidth='md'>
        <Box my={4}>
          <form>
            <FormControl fullWidth margin='normal' variant='outlined'>
              <InputLabel id='practiceTimeTargetLabel'>
                Pick a practice time target
              </InputLabel>
              <Select
                labelId='practiceTimeTargetLabel'
                id='practiceTimeTarget'
                value={formData.practiceTimeTarget}
                onChange={onPracticeTimeTargetChange}
                label='Pick a practice time target'>
                <MenuItem value='5'>5 minutes per day</MenuItem>
                <MenuItem value='10'>10 minutes per day</MenuItem>
                <MenuItem value='15'>15 minutes per day</MenuItem>
                <MenuItem value='20'>20 minutes per day</MenuItem>
                <MenuItem value='25'>25 minutes per day</MenuItem>
                <MenuItem value='30'>30 minutes per day</MenuItem>
              </Select>
            </FormControl>
            <Grid container spacing={2} justifyContent='space-between'>
              <Grid item>
                <Button color='primary' onClick={handleBack} variant='outlined'>
                  Back
                </Button>
              </Grid>
              <Grid item>
                <Button color='primary' onClick={onSubmit} variant='contained'>
                  Next
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </React.Fragment>
  )
}
