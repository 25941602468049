// VideoFrame.js
import React from 'react';
import Iframe from 'react-iframe';
import { Box, Paper } from '@mui/material';
import styled from '@emotion/styled';

const StyledPaper = styled(Paper)`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const StyledIframe = styled(Iframe)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const VideoFrame = ({ url }) => { 
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
      <StyledPaper elevation={3}>
        <StyledIframe
          url={url}
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        />
      </StyledPaper>
    </Box>
  );
};

export default VideoFrame;
