import React, { useState, useEffect } from 'react'
import { getAuth } from 'firebase/auth'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Button,
  Container,
  Grid,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../firebase/config' // VERY IMPORTANT: Confirm correct path to firebase config

export default function Step6() {
  const auth = getAuth()
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    weeklyTarget: '',
  })

  useEffect(() => {
    async function fetchUserData() {
      if (!auth.currentUser?.uid) {
        console.log('AUTH_CURRENT_USER_UID_NIL', auth.currentUser)
        return
      }
      const userDoc = doc(db, 'users', auth.currentUser.uid)
      const userSnapshot = await getDoc(userDoc)
      if (userSnapshot.exists()) {
        setFormData((prevState) => ({
          ...prevState,
          weeklyTarget: userSnapshot.data().weeklyTarget || '',
        }))
      }
    }
    fetchUserData()
  }, [auth.currentUser])

  const onWeeklyTargetChange = (event) => {
    setFormData({
      weeklyTarget: event.target.value,
    })
  }

  async function onSubmit() {
    try {
      const ref = doc(db, 'users', auth.currentUser.uid)
      await updateDoc(ref, {
        weeklyTarget: formData.weeklyTarget,
      })
      //toast.success('Weekly practice target updated')
      navigate('/intro/step-7') // VERY IMPORTANT: Adjust navigation path according to your routing configuration
    } catch (error) {
      toast.error('Could not update weekly practice target {users}.')
    }
  }

  const handleBack = () => {
    navigate(-1) // Navigate to the previous page
  }

  return (
    <React.Fragment>
      <Container maxWidth='md'>
        <Box my={4}>
          <form>
            <FormControl fullWidth margin='normal' variant='outlined'>
              <InputLabel id='weeklyTargetLabel'>
                Pick a weekly target
              </InputLabel>
              <Select
                labelId='weeklyTargetLabel'
                id='weeklyTarget'
                value={formData.weeklyTarget}
                onChange={onWeeklyTargetChange}
                label='Pick a weekly target'>
                <MenuItem value='1'>1 day a week</MenuItem>
                <MenuItem value='2'>2 days a week</MenuItem>
                <MenuItem value='3'>3 days a week</MenuItem>
                <MenuItem value='4'>4 days a week</MenuItem>
                <MenuItem value='5'>5 days a week</MenuItem>
                <MenuItem value='6'>6 days a week</MenuItem>
                <MenuItem value='7'>7 days a week</MenuItem>
              </Select>
            </FormControl>
            <Grid container spacing={2} justifyContent='space-between'>
              <Grid item>
                <Button color='primary' onClick={handleBack} variant='outlined'>
                  Back
                </Button>
              </Grid>
              <Grid item>
                <Button color='primary' onClick={onSubmit} variant='contained'>
                  Next
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </React.Fragment>
  )
}
