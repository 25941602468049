import React from 'react'
import { Box, Typography, Card, CardContent } from '@mui/material'
// import WhatshotIcon from '@mui/icons-material/Whatshot'
// import ActivityIcon from '@mui/icons-material/DirectionsRun'; // Uncomment if needed
// import MusicNoteIcon from '@mui/icons-material/MusicNote'; // Uncomment if needed
import { useTheme } from '@mui/material/styles'

function Streaks({ currentStreak /*, longestStreak, totalActivities */ }) {
  const theme = useTheme()

  const iconBoxStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    mx: 1,
  }

  return (
    <Card
      sx={{
        borderRadius: '20px',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'background.paper',
        color: 'text.primary',
        position: 'relative',
        overflow: 'hidden',
        maxWidth: 345,
        margin: 'auto',
        height: '100%', // Ensure the card takes full height
        '&:hover': { boxShadow: '0px 8px 40px rgba(0, 0, 0, 0.2)' },
        '@media (max-width: 600px)': { maxWidth: '100%' },
      }}>
      <CardContent style={{ padding: '16px', textAlign: 'center' }}>
        <Typography
          variant='h5'
          component='div'
          sx={{ fontWeight: 'bold', mb: 1 }}>
          Streaks
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={iconBoxStyle}>
            {/*<WhatshotIcon color='secondary' sx={{ mb: 1 }} />*/}
            <Typography variant='subtitle1'>Current Streak:</Typography>
            <Typography variant='h6'>{currentStreak} days</Typography>
          </Box>
          {/* Uncomment the sections below to include longest streak and total activities */}
          {/* <Box sx={iconBoxStyle}>
            <ActivityIcon color='primary' sx={{ mb: 1 }} />
            <Typography variant='subtitle1'>Longest Streak:</Typography>
            <Typography variant='h6'>{longestStreak} days</Typography>
          </Box>
          <Box sx={iconBoxStyle}>
            <ActivityIcon color='primary' sx={{ mb: 1 }} />
            <Typography variant='subtitle1'>Past 7 Days:</Typography>
            <Typography variant='h6'>{totalActivities}</Typography>
          </Box> */}
        </Box>
      </CardContent>
    </Card>
  )
}

export default Streaks
